<main>

  <div class="py-5 text-center">
    <h2>使用者建議地點</h2>
  </div>

  <div class="row g-5">

    <div class="col-12">
      <div class="list-group">
        <a class="list-group-item list-group-item-action"
          *ngFor="let submission of submissions | slice: (page-1) * pageSize : page * pageSize"
          routerLink="/backstage/submissions/{{submission.ID}}">
          <div class="d-flex">
            <div class="flex-shrink-0">
              <img [src]="submission.media[0].url" alt="..." class="img-fluid rounded">
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{submission.title}}</h5>
                <small
                [ngClass]="{
                  'approved' : submission.status == 'approved',
                  'rejected' : submission.status == 'rejected',
                  'submitted' : submission.status == 'submitted'
                }">
                  {{getStatusText(submission.status)}}
                </small>
              </div>
              <p class="mb-1">{{submission.created.toMillis() | date:'yyyy/MM/dd h:mm:ss a'}}</p>
              <small class="text-muted">{{submission.detail}}</small>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="col-12" *ngIf="submissions.length > 0">
      <ngb-pagination class="d-flex justify-content-center" [collectionSize]="submissions.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="onPageChange(page)"></ngb-pagination>
    </div>

    <div class="col-12" *ngIf="submissions.length == 0">
      <div class="py-5 text-center">
        <p>正在取得資料...</p>
      </div>
    </div>

  </div>

</main>
<footer class="my-5 pt-5 text-muted text-center text-small">
  <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
</footer>
