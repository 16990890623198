import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, OperatorFunction } from 'rxjs';
import { finalize, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { PlacesService } from '../places.service';
import { PrizeGoalsService } from '../prize-goals.service';
import { ArchitectsService } from '../architects.service';

@Component({
  selector: 'app-create-visit',
  templateUrl: './create-visit.component.html',
  styleUrls: ['./create-visit.component.css']
})
export class CreateVisitComponent implements OnInit {

  activePrizeGoals: any[] = [];
  architects: any[] = [];

  private usersCollection: AngularFirestoreCollection<any>;
  users: any[];
  searchUser: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.users.filter(a => {
          if (a.name) {
            return a.name.toLowerCase().indexOf(term.toLowerCase()) > -1;
          } else {
            return false
          }
        }).slice(0, 20)
      ),
    )
  userResultFormatter = (result: any) => {
    return `${result.name} (${result.uid})`
  };
  selectedUser?: any;

  isProccessing = false;

  places: any[];
  searchPlace: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.places.filter(a => {
          if (a.name.zh) {
            return a.name.zh.toLowerCase().indexOf(term.toLowerCase()) > -1;
          } else {
            return false
          }
        }).slice(0, 20)
      ),
    )
  placeResultFormatter = (result: any) => {
    return `${result.name.zh} (${result.ID})`
  };
  selectedPlace?: any;

  constructor(
    private firestore: AngularFirestore,
    private location: Location,
    private placesService: PlacesService,
    private prizeGoalsService: PrizeGoalsService,
    private architectsService: ArchitectsService,
  ) {
    this.users = [];
    this.usersCollection = firestore.collection('profiles');
    this.usersCollection.valueChanges({ idField: 'uid' })
      .subscribe(users => this.users = users);
    this.places = [];
    this.placesService.places
      .subscribe(places => this.places = places);
    this.prizeGoalsService.prizeGoals
      .subscribe(goals => {
        var now = new Date();
        this.activePrizeGoals = goals.filter(a => now <= a.end.toDate() && now > a.start.toDate())
      });
    this.architectsService.architects
      .subscribe(architects => this.architects = architects);
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  async save() {
    if (!this.selectedUser) {
      window.alert("請選擇使用者");
      return
    }
    if (!this.selectedPlace) {
      window.alert("請選擇打卡地點");
      return
    }
    this.isProccessing = true;

    // Prize gaols record
    var achievements = this.selectedUser.achievements || {};
    for (const goal of this.activePrizeGoals) {
      if (this.selectedPlace.prize_goals && this.selectedPlace.prize_goals[goal.ID]) {
        console.log(`${goal.ID} is active on this place`);
        var collectedPlaces = achievements[goal.ID] || {};
        collectedPlaces[this.selectedPlace.ID] = 1;
        achievements[goal.ID] = collectedPlaces;
      }
    }
    this.selectedUser.achievements = achievements;
    const result = await this.firestore.doc<any>(`profiles/${this.selectedUser.uid}`).update({
      achievements: achievements,
    });

    // Other achievements
    this.firestore.collection(
      'place-visits', ref => ref.where("visitor_uid", "==", this.selectedUser.uid).where("place_id", "==", this.selectedPlace.ID)
    ).get().subscribe(async visits => {

      if (visits.size > 0) {
        window.alert("此地點已打過卡。");
        this.selectedPlace = null;
        this.selectedUser = null;
        this.isProccessing = false;
      } else {

        const newVisit = await this.firestore.collection(`place-visits`).add({
          date: new Date(),
          invisible: false,
          place_architects: this.selectedPlace.architects,
          place_country: this.selectedPlace.country,
          place_id: this.selectedPlace.ID,
          place_name: this.selectedPlace.name,
          place_preview: this.selectedPlace.media[0] || null,
          place_subtitle: this.selectedPlace.subtitle,
          place_tags: this.selectedPlace.tags,
          visitor_avatar: this.selectedUser.avatar || null,
          visitor_name: this.selectedUser.name || null,
          visitor_uid: this.selectedUser.uid,
        });

        // Achievements
        var achievements = this.selectedUser.achievements || {};

        // Countries
        var countries = achievements["countries"] || {};
        var countryGoalCount = countries[this.selectedPlace.country] || 0;
        countryGoalCount += 1;
        countries[this.selectedPlace.country] = countryGoalCount;
        achievements["countries"] = countries;
        // Architects
        const placeArchitects = this.selectedPlace.architects || {};
        var architects = achievements["architects-pulitzer"] || {};
        const architectCodes = Object.keys(placeArchitects);
        for (const architectCode of architectCodes) {
            const architect = this.architects.find(e => e.ID == architectCode);
            if (!architect) { continue; }
            // Count pulitzer's
            if (architect.pulitzer == true) {
                var architectGoalCount = architects[architectCode] || 0;
                architectGoalCount += 1;
                architects[architectCode] = architectGoalCount;
            }
            // Count temp arch goals
            let tempArchGoals = Object.keys(architect.temp_goals || {});
            for (const tempArchGoal of tempArchGoals) {
                var tempArchGoalAchievement = achievements[tempArchGoal] || {};
                var architectGoalCount = tempArchGoalAchievement[architectCode] || 0;
                architectGoalCount += 1
                tempArchGoalAchievement[architectCode] = architectGoalCount;
                achievements[tempArchGoal] = tempArchGoalAchievement;
            }
        }
        achievements["architects-pulitzer"] = architects;

        this.selectedUser.achievements = achievements;
        const result = await this.firestore.doc<any>(`profiles/${this.selectedUser.uid}`).update({
          achievements: achievements,
        });

        window.alert("打卡建立成功!");
        this.selectedPlace = null;
        this.selectedUser = null;
        this.isProccessing = false;
      }
      console.log(visits);
    });

  }

}
