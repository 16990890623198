<div class="container">
  <main>
    <div class="py-5 text-center">
      <h2>手動打卡</h2>
    </div>

    <div class="row g-5">
      <div class="col">
        <h4 class="mb-3">填寫打卡資訊</h4>
        <form class="needs-validation" novalidate>
          <div class="row g-3">

            <div class="col-12">
              <label for="user" class="form-label">使用者</label>
              <div class="input-group">
                <input class="form-control" type="text" name="user" id="user" placeholder="選擇使用者" aria-label="選擇使用者"
                  [(ngModel)]="selectedUser" [ngbTypeahead]="searchUser" [resultFormatter]="userResultFormatter" [inputFormatter]="userResultFormatter"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>

            <div class="col-12">
              <label for="place" class="form-label">打卡地點</label>
              <div class="input-group">
                <input class="form-control" type="text" name="place" id="place" placeholder="選擇地點" aria-label="選擇地點"
                  [(ngModel)]="selectedPlace" [ngbTypeahead]="searchPlace" [resultFormatter]="placeResultFormatter" [inputFormatter]="placeResultFormatter"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>

          </div>

          <hr class="my-4">

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button class="btn btn-primary" [disabled]="isProccessing" type="submit" (click)="save()">建立打卡</button>
            <button class="btn btn-outline-secondary" [disabled]="isProccessing" type="button" (click)="goBack()">回上一頁</button>
          </div>

        </form>

      </div>
    </div>

  </main>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
  </footer>
</div>
