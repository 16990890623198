import { Component, OnInit } from '@angular/core';
import { placeTags } from '../place-tags';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

  placeTags = placeTags;

  constructor() { }

  ngOnInit(): void {
  }

}
