import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-launch-ad',
  templateUrl: './launch-ad.component.html',
  styleUrls: ['./launch-ad.component.css']
})
export class LaunchAdComponent implements OnInit {

  private doc: AngularFirestoreDocument<any>;
  launchAd?: any;
  uploadPercent?: number;
  showThumbnailUploadSuccess: boolean = false;
  isLoaded: boolean = false;

  constructor(
    firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) {
    this.doc = firestore.doc<any>('launch-ads/TW');
    this.doc.valueChanges()
      .subscribe(launchAd => {
        this.launchAd = launchAd;
        this.isLoaded = true;
      });
  }

  ngOnInit(): void {
  }

  delete(): void {
    if (this.launchAd) {
      if(confirm("確定要移除廣告嗎？")) {
        this.doc.delete()
          .then(() => {
            window.alert('移除成功');
            this.launchAd = null;
          }).catch(error => {
            window.alert(error.message);
          });
      }
    }
  }

  create(): void {
    this.launchAd = {
      "media": null,
      "link": null,
      "frequency": 10
    };
  }

  save(): void {
    if (!this.launchAd.media) {
      window.alert("請設定圖片");
      return
    }
    if (!this.launchAd.link) {
      window.alert("請設定點擊後開啟的連結");
      return
    }
    if (!this.launchAd.frequency) {
      window.alert("請設定廣告顯示頻率");
      return
    }
    this.doc.set(this.launchAd)
      .then(() => {
        window.alert("廣告設定成功");
      }).catch(error => {
        window.alert(error.message);
      });
  }

  thumbnailUpload(event: any): void {

    this.showThumbnailUploadSuccess = false;

    const file = event.target.files[0];
    const filePath = 'launch-ads/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task.percentageChanges().subscribe(progress => {
      if (progress) {
        this.uploadPercent = progress / 100.0;
      } else {
        this.uploadPercent = undefined;
      }
    });
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.launchAd.media = {
              "url": url,
              "type": "image",
              "aspect_ratio": 1.0
            };
            this.uploadPercent = undefined;
            this.showThumbnailUploadSuccess = true;
          });
        })
     )
    .subscribe();
  }

  hideThumbnailSuccess(): void {
    this.showThumbnailUploadSuccess = false;
  }


}
