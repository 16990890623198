import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { TempArchGoalsService } from '../temp-arch-goals.service';

@Component({
  selector: 'app-create-architect',
  templateUrl: './create-architect.component.html',
  styleUrls: ['./create-architect.component.css']
})
export class CreateArchitectComponent implements OnInit {

  tempArchGoals: any[] = [];

  architect: any = {
    name: {
    },
    count: 0,
    thumbnail: null,
    pulitzer: false,
    temp_goals: {}
  };

  uploadPercent?: number;
  showThumbnailUploadSuccess: boolean = false;

  private architectsCollection: AngularFirestoreCollection<any>;

  constructor(
    private router: Router,
    firestore: AngularFirestore,
    private location: Location,
    private storage: AngularFireStorage,
    private tempArchGoalsService: TempArchGoalsService,
  ) {
    this.architectsCollection = firestore.collection('architects');
    this.tempArchGoalsService.tempArchGoals
      .subscribe(goals => this.tempArchGoals = goals);
  }

  ngOnInit(): void {
  }

  thumbnailUpload(event: any): void {

    this.showThumbnailUploadSuccess = false;

    const file = event.target.files[0];
    const filePath = 'architects/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task.percentageChanges().subscribe(progress => {
      if (progress) {
        this.uploadPercent = progress / 100.0;
      } else {
        this.uploadPercent = undefined;
      }
    });
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.architect.thumbnail = url;
            this.uploadPercent = undefined;
            this.showThumbnailUploadSuccess = true;
          });
        })
     )
    .subscribe();
  }

  hideThumbnailSuccess(): void {
    this.showThumbnailUploadSuccess = false;
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    if (!this.architect.name.zh) {
      window.alert("名稱為必填");
      return
    }
    const newID = this.architect.name.zh.split('/').join('-');
    this.architectsCollection.doc(newID).set(this.architect)
      .then(() => {
        window.alert("建築師新增成功");
        this.router.navigateByUrl('backstage/architects/' + newID);
      }).catch(error => {
        window.alert(error.message);
      });
  }

  getImgSource(architect: any): string {
    const defaultImg = 'assets/icon-profile-empty.svg';
    if (architect.thumbnail && !(architect.thumbnail.includes('https://thumbnail.com') || architect.thumbnail.includes('https://thumnail.com'))) {
      return architect.thumbnail;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-profile-empty.svg';
  }

  tagToggled(event: any, id: string): void {
    if (event.target.checked) {
      this.architect.temp_goals[id] = true;
    } else {
      delete this.architect.temp_goals[id];
    }
  }


}
