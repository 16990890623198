<div class="container" *ngIf="submission">
  <main>
    <div class="py-5 text-center">
      <img [src]="getImgSource(submission)" (error) ="onImgError($event)" alt="..." class="img-fluid rounded-circle">
      <h2>建議地點</h2>
    </div>

    <div class="row g-5">
      <div class="col">
        <h4 class="mb-3">提交的內容</h4>
        <form class="needs-validation" novalidate>
          <div class="row g-3">

            <div class="col-12">
              <label for="title" class="form-label">標題</label>
              <input type="text" class="form-control" name="title" id="title" readonly
                [(ngModel)]="submission.title">
            </div>

            <div class="col-12">
              <label for="created" class="form-label">提交時間</label>
              <input type="text" class="form-control" name="created" id="created" [ngModel]="submission.created.toMillis() | date:'yyyy/MM/dd h:mm:ss a'" readonly>
            </div>

            <div class="col-12">
              <label for="email" class="form-label">Email</label>
              <input type="text" class="form-control" name="email" id="email" readonly
                [ngModel]="getEmail(submission)">
            </div>

            <div class="col-12">
              <label for="detail" class="form-label">內容</label>
              <textarea class="form-control" id="detail" rows="3" readonly>{{submission.detail}}</textarea>
            </div>

            <div class="col-12">
              <label class="form-label">提供的照片</label>
              <div class="d-flex flex-row flex-nowrap overflow-auto">
                <div *ngFor="let media of submission.media; let i = index" class="card">
                  <a (click)="openPhotoModal(media, i, false, true)">
                    <img src="{{ media.url }}" alt="..." class="rounded card-img overflow-hidden">
                    <div class="card-img-overlay">
                    </div>
                  </a>
                </div>
              </div>
            </div>

          </div>

          <hr class="my-4">

          <div *ngIf="submission.status=='submitted'">
            <h4 class="mb-3">審核 <span class="badge bg-secondary">填入完整的地點資料來完成審核</span></h4>

            <div class="row g-3">

              <div class="col-12">
                <label for="name" class="form-label">地點名稱</label>
                <input type="text" class="form-control" name="name" id="name" placeholder="#OMG" required
                  [(ngModel)]="place.name.zh">
                <div class="invalid-feedback">
                  不可接受的地點名稱
                </div>
              </div>

              <div class="col-12">
                <label for="subtitle" class="form-label">描述</label>
                <input type="text" class="form-control" name="subtitle" id="subtitle" placeholder="建築師事務所設計" required
                  [(ngModel)]="place.subtitle.zh">
                <div class="invalid-feedback">
                  不可接受的描述
                </div>
              </div>

              <div class="col-md-3">
                <label for="country" class="form-label">國家</label>
                <select class="form-select" name="country" id="country" required
                  [(ngModel)]="place.country">
                  <option *ngFor="let country of countries" [ngValue]="country.ID">{{country.name.zh}}</option>
                </select>
                <div class="invalid-feedback">
                  請選擇國家
                </div>
              </div>

              <div class="col-md-9">
                <label for="address" class="form-label">地址</label>
                <input type="text" class="form-control" name="address" id="address" placeholder="香港大角咀海泓道1號" required
                  [(ngModel)]="place.address.zh">
                <div class="invalid-feedback">
                  不可接受的地址
                </div>
              </div>

              <div class="col-12">
                <button type="button" class="btn btn-outline-primary" (click)="customCountry()">找不到國家?</button>
              </div>

              <div class="col-md-4">
                <label for="latitude" class="form-label">緯度</label>
                <input type="text" class="form-control" name="latitude" id="latitude" placeholder="23.225" required
                  [ngModel]="place.geopoint.latitude" (ngModelChange)="latitudeChanged($event)">
                <div class="invalid-feedback">
                  緯度為必填
                </div>
              </div>

              <div class="col-md-4">
                <label for="longitude" class="form-label">經度</label>
                <input type="text" class="form-control" name="longitude" id="longitude" placeholder="121.2511" required
                  [ngModel]="place.geopoint.longitude" (ngModelChange)="longitudeChanged($event)">
                <div class="invalid-feedback">
                  經度為必填
                </div>
              </div>

              <div class="col-md-4">
                <label for="geohash" class="form-label">Geohash(自動計算)</label>
                <input type="text" class="form-control" name="geohash" id="geohash" placeholder="" required
                  [(ngModel)]="place.geohash" readonly>
              </div>


              <div class="col-12">
                <label for="navigation_url" class="form-label">地圖網址</label>
                <input type="text" class="form-control" name="navigation_url" id="navigation_url"
                  placeholder="https://goo.gl/maps/CYNAaGxB8dJapdUi6"
                  required
                  [(ngModel)]="place.navigation_url">
                <div class="invalid-feedback">
                  不可接受的地圖網址
                </div>
              </div>

            </div>

            <hr class="my-4">

            <h4 class="mb-3">照片</h4>

            <div class="row g-3">

              <div class="d-flex flex-row flex-nowrap overflow-auto">
                <div *ngFor="let media of place.media; let i = index" class="card">
                  <a (click)="openPhotoModal(media, i, true, false)">
                    <img src="{{ media.url }}" alt="..." class="rounded card-img overflow-hidden">
                    <div class="card-img-overlay">
                    </div>
                  </a>
                </div>
              </div>

              <div class="col-12">
                <div class="mb-3">
                  <label for="addPhoto" class="form-label">新增照片</label>
                  <input class="form-control" type="file" id="addPhoto" accept=".jpg,.png"
                    (change)="photoUpload($event)">
                </div>
                <div class="alert alert-secondary" role="alert" *ngIf="uploadPercent">
                  正在上傳 <strong>{{ uploadPercent | percent }}</strong>
                </div>
                <div class="alert alert-success alert-dismissible fade show" role="alert"
                  *ngIf="showPhotoUploadSuccess">
                  <strong>上傳成功!</strong> 你可以持續編輯照片，直到按下儲存鈕
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="hidePhotoSuccess()"></button>
                </div>
              </div>

            </div>

            <hr class="my-4">

            <h4 class="mb-3">建築師</h4>

            <div class="row g-3">

              <div class="input-group"
                *ngFor="let item of place.architects | keyvalue">
                <input type="text" class="form-control" value="{{item.key}}" readonly>
                <button type="button" class="btn btn-danger" (click)="removeArchitect(item.key)">移除</button>
              </div>

              <div class="input-group">
                <input class="form-control" type="text" placeholder="加入建築師" aria-label="加入建築師"
                  [(ngModel)]="selectedArchitect" [ngbTypeahead]="searchArchitect" [resultFormatter]="architectResultFormatter" [inputFormatter]="architectResultFormatter"
                  [ngModelOptions]="{standalone: true}">
                <button type="button" class="btn btn-primary" (click)="addArchitect()">加入</button>
              </div>

              <div class="col-12 text-end">
                <button type="button" class="btn btn-outline-primary" (click)="customArchitect()">找不到建築師?</button>
              </div>

            </div>

            <hr class="my-4">

            <h4 class="mb-3">分類</h4>

            <div class="row g-3">

              <div class="col-md-4" *ngFor="let tag of placeTags">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" name="tag-{{tag.id}}" id="tag-{{tag.id}}"
                    [checked]="place.tags[tag.id]"
                    (change)="tagToggled($event, tag.id)">
                  <label class="form-check-label" for="tag-{{tag.id}}">{{tag.name}}</label>
                </div>
              </div>

            </div>

            <hr class="my-4">

            <h4 class="mb-3">連結</h4>

            <div class="row g-3">

              <div class="input-group"
                *ngFor="let link of place.links; let i = index">
                <input type="text" class="form-control" value="{{link}}" readonly>
                <button type="button" class="btn btn-danger" (click)="removeLink(i)">移除</button>
              </div>

              <div class="input-group">
                <input type="text" class="form-control" placeholder="連結網址"
                  [(ngModel)]="linkInput" [ngModelOptions]="{standalone: true}">
                <button type="button" class="btn btn-primary" (click)="addLink()">新增</button>
              </div>

            </div>

            <hr class="my-4">

            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <button class="btn btn-success" type="submit" (click)="save()">通過審核並建立新地點</button>
              <button class="btn btn-danger" type="submit" (click)="reject()">拒絕</button>
              <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
            </div>

          </div>

          <div *ngIf="submission.status=='rejected'">
            <h4 class="mb-3">審核狀態 <span class="badge bg-danger">已拒絕</span></h4>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
            </div>
          </div>

          <div *ngIf="submission.status=='approved'">
            <h4 class="mb-3">審核狀態 <span class="badge bg-success">已通過</span></h4>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <button class="btn btn-success" type="submit" (click)="go()">查看地點</button>
              <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
            </div>
          </div>

        </form>

      </div>
    </div>
  </main>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
  </footer>
</div>
