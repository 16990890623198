import { Component, OnInit } from '@angular/core';
import { SubmissionsService } from '../submissions.service';

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.css']
})
export class SubmissionsComponent implements OnInit {

  submissions: any[] = [];
  page = 1;
  pageSize = 20;

  constructor(
    private submissionsService: SubmissionsService,
  ) {
    this.submissionsService.submissions
      .subscribe(submissions => this.submissions = submissions);
    this.page = this.submissionsService.page;
  }

  ngOnInit(): void {
  }

  public onPageChange(pageNum: number): void {
    this.submissionsService.page = pageNum;
  }

  getStatusText(status: string): string {
    if (status == 'submitted') {
      return '等待審核';
    } else if (status == 'approved') {
      return '已通過';
    } else if (status == 'rejected') {
      return '已拒絕';
    }
    return '未知的狀態';
  }
}
