import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-country',
  templateUrl: './create-country.component.html',
  styleUrls: ['./create-country.component.css']
})
export class CreateCountryComponent implements OnInit {

  country: any = {
    name: {
    },
    count: 0,
    thumbnail: null,
    emoji: ""
  };

  uploadPercent?: number;
  showThumbnailUploadSuccess: boolean = false;

  private countriesCollection: AngularFirestoreCollection<any>;


  constructor(
    private router: Router,
    firestore: AngularFirestore,
    private location: Location,
    private storage: AngularFireStorage
  ) {
    this.countriesCollection = firestore.collection('countries');
  }

  ngOnInit(): void {
  }

  thumbnailUpload(event: any): void {

    this.showThumbnailUploadSuccess = false;

    const file = event.target.files[0];
    const filePath = 'countries/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task.percentageChanges().subscribe(progress => {
      if (progress) {
        this.uploadPercent = progress / 100.0;
      } else {
        this.uploadPercent = undefined;
      }
    });
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.country.thumbnail = url;
            this.uploadPercent = undefined;
            this.showThumbnailUploadSuccess = true;
          });
        })
     )
    .subscribe();
  }

  hideThumbnailSuccess(): void {
    this.showThumbnailUploadSuccess = false;
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    if (!this.country.name.zh) {
      window.alert("名稱為必填");
      return
    }
    if (!this.country.emoji) {
      window.alert("Emoji圖示為必填");
      return
    }
    const newID = this.country.name.zh.split('/').join('-');
    this.countriesCollection.doc(newID).set(this.country)
      .then(() => {
        window.alert("國家新增成功");
        this.router.navigateByUrl('backstage/countries/' + newID);
      }).catch(error => {
        window.alert(error.message);
      });
  }

  getImgSource(country: any): string {
    const defaultImg = 'assets/icon-pic-empty.svg';
    if (country.thumbnail && !(country.thumbnail.includes('https://thumbnail.com') || country.thumbnail.includes('https://thumnail.com'))) {
      return country.thumbnail;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-pic-empty.svg';
  }


}
