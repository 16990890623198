import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Location } from '@angular/common';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-temp-arch-goals-detail',
  templateUrl: './temp-arch-goals-detail.component.html',
  styleUrls: ['./temp-arch-goals-detail.component.css']
})
export class TempArchGoalsDetailComponent implements OnInit {

  participants$: Observable<any[]>;

  private doc: AngularFirestoreDocument<any>;
  goal?: any;
  showSuccess: boolean = false;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  constructor(
    private route: ActivatedRoute,
    firestore: AngularFirestore,
    private location: Location,
    calendar: NgbCalendar
  ) {
    const id = this.route.snapshot.paramMap.get('id');
    this.participants$ = firestore.collection('architects', ref => ref.where('temp_goals.'+id, '==', true)).valueChanges({ idField: 'ID' });
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.doc = firestore.doc<any>('temp-arch-goals/'+id);
    this.doc.valueChanges()
      .subscribe(goal => {
        this.goal = goal;
        this.fromDate = new NgbDate(goal.start.toDate().getFullYear(), goal.start.toDate().getMonth() + 1, goal.start.toDate().getDate());
        this.toDate = new NgbDate(goal.end.toDate().getFullYear(), goal.end.toDate().getMonth() + 1, goal.end.toDate().getDate());
      });
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {

    this.showSuccess = false;

    if (!this.fromDate) {
      window.alert("請選擇開始日期");
      return;
    }

    if (!this.toDate) {
      window.alert("請選擇結束日期");
      return;
    }

    this.goal.start = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    this.goal.end = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);

    var reg=/^#[0-9A-F]{6}$/i;
    if (!reg.test(this.goal.color)) {
      window.alert("請輸入正確的色碼");
      return;
    }

    if (this.goal) {
      this.doc.update(this.goal)
        .then(() => {
          this.showSuccess = true;
        }).catch(error => {
          window.alert(error.message);
        });
    }
  }

  hideSuccess(): void {
    this.showSuccess = false;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  getDateText(date: NgbDate | null) {
    return date == null ?  '選擇日期' : date.year + '/' + date.month + '/' + date.day;
  }

  getImgSource(architect: any): string {
    const defaultImg = 'assets/icon-profile-empty.svg';
    if (architect.thumbnail && !(architect.thumbnail.includes('https://thumbnail.com') || architect.thumbnail.includes('https://thumnail.com'))) {
      return architect.thumbnail;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-profile-empty.svg';
  }


}
