import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { IconsModule } from './icons/icons.module'
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';

import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { LoginComponent } from './login/login.component';
import { BackstageComponent } from './backstage/backstage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlacesComponent } from './places/places.component';
import { ArchitectsComponent } from './architects/architects.component';
import { ArchitectDetailComponent } from './architect-detail/architect-detail.component';
import { PlaceDetailComponent } from './place-detail/place-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PhotoModalComponent } from './photo-modal/photo-modal.component';
import { TextInputModalComponent } from './text-input-modal/text-input-modal.component';
import { CreatePlaceComponent } from './create-place/create-place.component';
import { CreateArchitectComponent } from './create-architect/create-architect.component';
import { CountriesComponent } from './countries/countries.component';
import { CountryDetailComponent } from './country-detail/country-detail.component';
import { CreateCountryComponent } from './create-country/create-country.component';
import { SubmissionsComponent } from './submissions/submissions.component';
import { SubmissionDetailComponent } from './submission-detail/submission-detail.component';
import { TempArchGoalsComponent } from './temp-arch-goals/temp-arch-goals.component';
import { TempArchGoalsDetailComponent } from './temp-arch-goals-detail/temp-arch-goals-detail.component';
import { CreateTempArchGoalComponent } from './create-temp-arch-goal/create-temp-arch-goal.component';
import { LaunchAdComponent } from './launch-ad/launch-ad.component';
import { TagsComponent } from './tags/tags.component';
import { PlacesByTagComponent } from './places-by-tag/places-by-tag.component';
import { AccountRequestsComponent } from './account-requests/account-requests.component';
import { PrizeGoalsComponent } from './prize-goals/prize-goals.component';
import { CreatePrizeGoalsComponent } from './create-prize-goals/create-prize-goals.component';
import { PrizeGoalDetailComponent } from './prize-goal-detail/prize-goal-detail.component';
import { CreateVisitComponent } from './create-visit/create-visit.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BackstageComponent,
    DashboardComponent,
    PlacesComponent,
    ArchitectsComponent,
    ArchitectDetailComponent,
    PlaceDetailComponent,
    PhotoModalComponent,
    TextInputModalComponent,
    CreatePlaceComponent,
    CreateArchitectComponent,
    CountriesComponent,
    CountryDetailComponent,
    CreateCountryComponent,
    SubmissionsComponent,
    SubmissionDetailComponent,
    TempArchGoalsComponent,
    TempArchGoalsDetailComponent,
    CreateTempArchGoalComponent,
    LaunchAdComponent,
    TagsComponent,
    PlacesByTagComponent,
    AccountRequestsComponent,
    PrizeGoalsComponent,
    CreatePrizeGoalsComponent,
    PrizeGoalDetailComponent,
    CreateVisitComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    IconsModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
