<div class="container" *ngIf="architect">
  <main>
    <div class="py-5 text-center">
      <img [src]="getImgSource(architect)" (error) ="onImgError($event)" alt="..." class="img-fluid rounded-circle mb-2">
      <h2>新增建築師</h2>
    </div>

    <div class="row g-5">
      <div class="col">
        <h4 class="mb-3">建築師資料</h4>
        <form class="needs-validation" novalidate>
          <div class="row g-3">

            <div class="col-12">
              <label for="name" class="form-label">名稱</label>
              <input type="text" class="form-control" name="name" id="name" placeholder="黃家祥-木介設計" required
                [(ngModel)]="architect.name.zh">
              <div class="invalid-feedback">
                不可接受的建築師名稱
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="thumbnailFile" class="form-label">更換照片</label>
                <input class="form-control" type="file" id="thumbnailFile" accept=".jpg,.png"
                  (change)="thumbnailUpload($event)">
              </div>
              <div class="alert alert-secondary" role="alert" *ngIf="uploadPercent">
                正在上傳 <strong>{{ uploadPercent | percent }}</strong>
              </div>
              <div class="alert alert-success alert-dismissible fade show" role="alert"
                *ngIf="showThumbnailUploadSuccess">
                <strong>上傳成功!</strong> 你可以持續更換其他照片，直到按下儲存鈕
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="hideThumbnailSuccess()"></button>
              </div>
            </div>

          </div>

          <hr class="my-4">

          <h4 class="mb-3">分類</h4>

          <div class="form-check">
            <input type="checkbox" class="form-check-input" name="pulitzer" id="pulitzer"
              [(ngModel)]="architect.pulitzer">
            <label class="form-check-label" for="pulitzer">普立茲克建築師</label>
          </div>

          <hr class="my-4">

          <h4 class="mb-3">參與短期活動展演</h4>

          <div class="row g-3">

            <div class="col-md-4" *ngFor="let goal of tempArchGoals">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="goal-{{goal.ID}}" id="goal-{{goal.ID}}"
                  [checked]="architect.temp_goals[goal.ID]"
                  (change)="tagToggled($event, goal.ID)">
                <label class="form-check-label" for="goal-{{goal.ID}}">{{goal.name.zh}}</label>
              </div>
            </div>

          </div>

          <hr class="my-4">

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button class="btn btn-primary" type="submit" (click)="save()">儲存</button>
            <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
          </div>

        </form>

      </div>
    </div>
  </main>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
  </footer>
</div>
