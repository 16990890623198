import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-text-input-modal',
  templateUrl: './text-input-modal.component.html',
  styleUrls: ['./text-input-modal.component.css']
})
export class TextInputModalComponent implements OnInit {

  @Input() title: any;

  textInput?: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  commit(): void {
    if (this.textInput) {
      this.activeModal.close(this.textInput);
    }
  }
}
