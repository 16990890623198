import { Component, OnInit } from '@angular/core';
import { PlacesService } from '../places.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css']
})
export class PlacesComponent implements OnInit {

  places: any[] = [];
  page = 1;
  pageSize = 20;

  constructor(
    private placesService: PlacesService,
    private router: Router,
  ) {
    this.placesService.places
      .subscribe(places => this.places = places);
    this.page = this.placesService.page;
  }

  ngOnInit(): void {
  }

  architectsToString(architects: any): string {
    return Object.keys(architects).join(", ")
  }

  public onPageChange(pageNum: number): void {
    this.placesService.page = pageNum;
  }

  getImgSource(place: any): string {
    const defaultImg = 'assets/icon-pic-empty.svg';
    if (place.media && place.media.length > 0) {
      return place.media[0].url;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-pic-empty.svg';
  }

  add(): void {
    this.router.navigateByUrl('backstage/places/create');
  }

}
