import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  countries: Observable<any[]>;
  page: number = 1;

  constructor(firestore: AngularFirestore) {
    this.countries = firestore.collection('countries').valueChanges({ idField: 'ID' });
  }
}
