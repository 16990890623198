import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from "@angular/router";

import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loggingIn: boolean = false;
  loginForm = this.formBuilder.group({
      email: '',
      password: ''
    });

  constructor(
    public auth: AngularFireAuth,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.loggingIn = true;
    this.signin(this.loginForm.value.email, this.loginForm.value.password);
  }

  signup(email: string, password: string) {
    this.auth.createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        window.alert("You have been successfully registered!");
        console.log(userCredential.user);
        this.router.navigate(['backstage']);
      }).catch((error) => {
        window.alert(error.message);
        this.loggingIn = false;
      });
  }

  signin(email: string, password: string) {
    this.auth.signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        console.log(userCredential.user)
        this.router.navigate(['backstage']);
      }).catch((error) => {
        window.alert(error.message);
        this.loggingIn = false;
      });
  }

}
