<main>

  <div class="py-5 text-center">
    <h2>{{ this.tag.name }}</h2>
  </div>

  <div *ngIf="places$ | async; let places; else loading">

    <div class="row g-5">
      <div class="col-12">
        <div class="list-group">
          <a class="list-group-item list-group-item-action"
            *ngFor="let place of places | slice: (page-1) * pageSize : page * pageSize"
            routerLink="/backstage/places/{{place.ID}}">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img [src]="getImgSource(place)" alt="..." class="img-fluid rounded"
                  (error) ="onImgError($event)">
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">{{place.name.zh}}</h5>
                  <small class="text-muted">{{place.country}}</small>
                </div>
                <p class="mb-1">{{architectsToString(place.architects)}}</p>
                <small class="text-muted">{{place.subtitle.zh}}</small>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-12" *ngIf="places.length > 0">
        <ngb-pagination class="d-flex justify-content-center" [collectionSize]="places.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="onPageChange(page)"></ngb-pagination>
      </div>
      <div *ngIf="places.length === 0" class="py-5 text-center">目前尚無建築景點</div>
    </div>

  </div>

  <ng-template #loading>
    <div class="py-5 text-center">
      <p>讀取中</p>
    </div>
  </ng-template>

  <div class="d-grid gap-2 d-md-flex justify-content-md-center">
    <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
  </div>

</main>

<footer class="my-5 pt-5 text-muted text-center text-small">
  <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
</footer>
