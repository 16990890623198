<div class="container" *ngIf="goal">
  <main>
    <div class="py-5 text-center">
      <h2>新增集章兌獎活動</h2>
    </div>

    <div class="row g-5">
      <div class="col">
        <h4 class="mb-3">基本設定</h4>
        <form class="needs-validation" novalidate>
          <div class="row g-3">

            <div class="col-12">
              <label for="name" class="form-label">活動名稱</label>
              <input type="text" class="form-control" name="name" id="name" placeholder="打開台北" required
                [(ngModel)]="goal.name.zh">
            </div>

            <div class="col-md-6">
              <label for="color" class="form-label">主題色</label><span class="badge" [ngStyle]="{'background-color': goal.color}">預覽</span>
              <input type="text" class="form-control" name="color" id="color" placeholder="hex色碼" required
                [(ngModel)]="goal.color">
            </div>

            <div class="col-md-6">
              <label for="frequency" class="form-label">兌獎資格 (至少在幾個地點打卡可以兌獎?)</label>
              <input type="number" class="form-control" name="frequency" id="frequency" placeholder="10" required
                [(ngModel)]="goal.qualification">
            </div>

            <hr class="my-4">

            <h4 class="mb-3">時間設定</h4>

            <div class="col-md-3">
              <label for="start" class="form-label">活動上架日期</label>
              <input type="text" class="form-control" name="start" id="start" placeholder="開始日期" required
                value="{{ getDateText(fromDate) }}" readonly>
            </div>

            <div class="col-md-3">
              <label for="end" class="form-label">活動下架日期</label>
              <input type="text" class="form-control" name="end" id="end" placeholder="結束日期" required
                value="{{ getDateText(toDate) }}" readonly>
            </div>

            <div class="col-12">
              <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
              </ngb-datepicker>
              <ng-template #t let-date let-focused="focused">
                <span class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>

            <div class="row g-3" *ngIf="fromDate && toDate">
              <div class="col-md-3">
                <label for="redeem_start" class="form-label">開始打卡與兌獎的日期</label>
                <input type="text" class="form-control" name="redeem_start" id="redeem_start" placeholder="開始兌獎日期" required
                  value="{{ getDateText(redeemFromDate) }}" readonly>
              </div>

              <div class="col-md-3">
                <label for="redeem_end" class="form-label">結束打卡與兌獎的日期</label>
                <input type="text" class="form-control" name="redeem_end" id="redeem_end" placeholder="結束兌獎日期" required
                  value="{{ getDateText(redeemToDate) }}" readonly>
              </div>

              <div class="col-12">
                <ngb-datepicker #rdp (dateSelect)="onRedeemDateSelection($event)"
                  [displayMonths]="2"
                  [dayTemplate]="rt"
                  [markDisabled]="isRedeemDisabled"
                  outsideDays="hidden">
                </ngb-datepicker>
                <ng-template #rt let-date let-focused="focused" let-disabled="disabled">
                  <span class="custom-day"
                        [class.focused]="focused"
                        [class.range]="isRedeemRange(date)"
                        [class.faded]="isRedeemHovered(date) || isRedeemInside(date)"
                        [class.text-muted]="disabled"
                        (mouseenter)="redeemHoveredDate = date"
                        (mouseleave)="redeemHoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
              <p class="mb-1">*打卡與兌換日期必須於上架期間內</p>
            </div>

            <hr class="my-4">

            <h4 class="mb-3">活動規則連結</h4>

            <div class="col-12">
              <label for="name" class="form-label">連結</label>
              <input type="text" class="form-control" name="info_link" id="info_link" placeholder="https://www.opentaipei.org/index.html" required
                [(ngModel)]="goal.info_link">
            </div>

            <hr class="my-4">

            <h4 class="mb-3">通知設定</h4>

            <div class="col-md-12">
              <label class="form-check-label" for="flexSwitchCheckDefault">使用者在附近時推播通知</label>
              <div class="form-check form-switch form-switch-lg">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" required
                  [checked]="goal.nearby_alert"
                  (change)="nearbyAlertToggled($event)">
              </div>
            </div>

          </div>

          <hr class="my-4">

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button class="btn btn-primary" type="submit" (click)="save()">儲存</button>
            <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
          </div>

        </form>

      </div>
    </div>
  </main>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
  </footer>
</div>
