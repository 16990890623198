<div class="container" *ngIf="country">
  <main>
    <div class="py-5 text-center">
      <img [src]="getImgSource(country)" (error) ="onImgError($event)" alt="..." class="img-fluid rounded-circle">
      <h2>{{ country.name.zh }}</h2>
    </div>

    <div class="row g-5">
      <div class="col">
        <h4 class="mb-3">國家資料</h4>
        <form class="needs-validation" novalidate>
          <div class="row g-3">

            <div class="col-8">
              <label for="name" class="form-label">名稱</label>
              <input type="text" class="form-control" name="name" id="name" placeholder="台灣" required
                [(ngModel)]="country.name.zh">
              <div class="invalid-feedback">
                不可接受的國家名稱
              </div>
            </div>

            <div class="col-4">
              <label for="emoji" class="form-label">Emoji圖示</label>
              <input type="text" class="form-control" name="emoji" id="emoji" placeholder="emoji" required
                [(ngModel)]="country.emoji">
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="thumbnailFile" class="form-label">更換照片</label>
                <input class="form-control" type="file" id="thumbnailFile" accept=".jpg,.png"
                  (change)="thumbnailUpload($event)">
              </div>
              <div class="alert alert-secondary" role="alert" *ngIf="uploadPercent">
                正在上傳 <strong>{{ uploadPercent | percent }}</strong>
              </div>
              <div class="alert alert-success alert-dismissible fade show" role="alert"
                *ngIf="showThumbnailUploadSuccess">
                <strong>上傳成功!</strong> 你可以持續更換其他照片，直到按下儲存鈕
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="hideThumbnailSuccess()"></button>
              </div>
            </div>

          </div>

          <hr class="my-4">

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button class="btn btn-primary" type="submit" (click)="save()">儲存</button>
            <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
          </div>

          <hr class="my-4">

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button class="btn btn-danger" type="button" (click)="delete()">刪除這個國家</button>
          </div>

        </form>

      </div>
      <div class="alert alert-success alert-dismissible fade show" role="alert"
        *ngIf="showSuccess">
        <strong>儲存成功!</strong> 你可以繼續編輯，或回上一頁
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="hideSuccess()"></button>
      </div>
    </div>
  </main>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
  </footer>
</div>
