import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaSubmissionsService {

  submissions: Observable<any[]>;
  page: number = 1;

  constructor(firestore: AngularFirestore) {
    this.submissions = firestore.collection('photo-submit-logs', ref => ref.orderBy('created', 'desc'))
      .valueChanges({ idField: 'ID' });
  }
}
