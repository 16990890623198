<div class="container" *ngIf="goal">
  <main>
    <div class="py-5 text-center">
      <h2>{{ goal.name.zh }}</h2>
    </div>

    <div class="row g-5">
      <div class="col">
        <h4 class="mb-3">參與建築師</h4>
        <div class="row g-3">
          <div *ngIf="participants$ | async; let participants; else loading" class="row row-cols-1 row-cols-md-3 g-4">

            <div class="col" *ngFor="let participant of participants">

              <div class="card">
                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img [src]="getImgSource(participant)" alt="..." class="img-fluid rounded"
                        (error) ="onImgError($event)">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h5 class="mt-0">{{participant.name.zh}}</h5>
                      <p>{{participant.pulitzer ? "普立茲克建築師" : "建築師"}}</p>
                    </div>
                  </div>
                  <a routerLink="/backstage/architects/{{participant.ID}}" class="stretched-link"></a>
                </div>
              </div>

            </div>
            <div *ngIf="participants.length === 0">目前無參與建築師</div>
          </div>
          <ng-template #loading>讀取中</ng-template>
        </div>

        <hr class="my-4">

      </div>
    </div>

    <div class="row g-5">
      <div class="col">
        <h4 class="mb-3">設定</h4>
        <form class="needs-validation" novalidate>
          <div class="row g-3">

            <div class="col-md-3">
              <label for="start" class="form-label">開始日期</label>
              <input type="text" class="form-control" name="start" id="start" placeholder="開始日期" required
                value="{{ getDateText(fromDate) }}" readonly>
            </div>

            <div class="col-md-3">
              <label for="end" class="form-label">結束日期</label>
              <input type="text" class="form-control" name="end" id="end" placeholder="結束日期" required
                value="{{ getDateText(toDate) }}" readonly>
            </div>

            <div class="col-12">
              <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
              </ngb-datepicker>
              <ng-template #t let-date let-focused="focused">
                <span class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>

            <div class="col-md-6">
              <label for="color" class="form-label">主題色</label><span class="badge" [ngStyle]="{'background-color': goal.color}">預覽</span>
              <input type="text" class="form-control" name="color" id="color" placeholder="hex色碼" required
                [(ngModel)]="goal.color">
            </div>

          </div>

          <hr class="my-4">

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button class="btn btn-primary" type="submit" (click)="save()">儲存</button>
            <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
          </div>

        </form>

      </div>
      <div class="alert alert-success alert-dismissible fade show" role="alert"
        *ngIf="showSuccess">
        <strong>儲存成功!</strong> 你可以繼續編輯，或回上一頁
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="hideSuccess()"></button>
      </div>
    </div>
  </main>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
  </footer>
</div>
