import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Location } from '@angular/common';
import { finalize, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';
import * as geohash from 'ngeohash';
import * as fs from 'firebase/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhotoModalComponent } from '../photo-modal/photo-modal.component';
import { TextInputModalComponent } from '../text-input-modal/text-input-modal.component';
import { placeTags } from '../place-tags';
import { PrizeGoalsService } from '../prize-goals.service';

@Component({
  selector: 'app-create-place',
  templateUrl: './create-place.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./create-place.component.css']
})
export class CreatePlaceComponent implements OnInit {

  prizeGoals: any[] = [];

  place: any = {
    name: {
    },
    subtitle: {
    },
    address: {
    },
    geopoint: new fs.GeoPoint(0.0, 0.0),
    geohash: "7zzzzzzzzz",
    navigation_url: null,
    architects: {},
    tags: {},
    country: null,
    media: [],
    bookmarks: 0,
    links: [],
    prize_goals: {},
  };
  countries: any[];

  private architectsCollection: AngularFirestoreCollection<any>;
  architects: any[];

  showSuccess: boolean = false;

  uploadPercent?: number;
  showPhotoUploadSuccess: boolean = false;

  linkInput: string = "";

  searchArchitect: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.architects.filter(a => a.name.zh.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 20)
      ),
    )

  architectResultFormatter = (result: any) => {
    return result.name.zh
  };
  selectedArchitect?: any;

  placeTags = placeTags;

  private placesCollection: AngularFirestoreCollection<any>;

  constructor(
    private router: Router,
    private firestore: AngularFirestore,
    private location: Location,
    private storage: AngularFireStorage,
    private modalService: NgbModal,
    private prizeGoalsService: PrizeGoalsService,
  ) {
    this.countries = [];
    firestore.collection('countries').valueChanges({ idField: 'ID' })
      .subscribe(countries => this.countries = countries);
    this.architects = [];
    this.architectsCollection = firestore.collection('architects');
    this.architectsCollection.valueChanges({ idField: 'ID' })
      .subscribe(architects => this.architects = architects);
    this.placesCollection = firestore.collection('places');
    this.prizeGoalsService.prizeGoals
      .subscribe(goals => this.prizeGoals = goals);
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  getCountry(countryID: string): any {
    return this.countries.find(c => c.ID === countryID);
  }

  latitudeChanged(latitude: any): void {
    this.place.geopoint = new fs.GeoPoint(latitude, this.place.geopoint.longitude);
    this.updateGeohash();
  }

  longitudeChanged(longitude: any): void {
    this.place.geopoint = new fs.GeoPoint(this.place.geopoint.latitude, longitude);
    this.updateGeohash();
  }

  updateGeohash(): void {
    const newGeohash = geohash.encode(this.place.geopoint.latitude, this.place.geopoint.longitude, 10);
    this.place.geohash = newGeohash;
  }

  photoUpload(event: any): void {

    this.showPhotoUploadSuccess = false;

    const file = event.target.files[0];
    const filePath = 'places/' + this.place.ID + '/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task.percentageChanges().subscribe(progress => {
      if (progress) {
        this.uploadPercent = progress / 100.0;
      } else {
        this.uploadPercent = undefined;
      }
    });
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.place.media.push(
              {
                'aspect_ratio': 1.0,
                'type': 'image',
                'url': url
              }
            );
            this.uploadPercent = undefined;
            this.showPhotoUploadSuccess = true;
          });
        })
     )
    .subscribe();
  }

  hidePhotoSuccess(): void {
    this.showPhotoUploadSuccess = false;
  }

  openPhotoModal(media: any, index: number) {
    const modalRef = this.modalService.open(PhotoModalComponent, { centered: true });
    modalRef.componentInstance.media = media;
    modalRef.componentInstance.mediaIndex = index;
    modalRef.result.then((result) => {
      if (result !== undefined) {
        this.place.media.splice(result, 1);
      }
    });
  }

  removeLink(index: number) {
    this.place.links.splice(index, 1);
  }

  addLink() {
    if (this.linkInput) {
      this.place.links.push(this.linkInput);
      this.linkInput = "";
    }
  }

  removeArchitect(name: any) {
    delete this.place.architects[name];
  }

  getArchitect(architectID: string): any {
    return this.architects.find(a => a.ID === architectID);
  }

  addArchitect() {
    if (this.selectedArchitect.ID) {
      this.place.architects[this.selectedArchitect.ID] = true;
      this.selectedArchitect = undefined;
    }
  }

  customArchitect() {
    const modalRef = this.modalService.open(TextInputModalComponent, { centered: true });
    modalRef.componentInstance.title = "新增建築師";
    modalRef.result.then((result) => {
      if (result !== undefined) {
        const architectID = result.split('/').join('-');
        const existingArchitect = this.getArchitect(architectID);
        if (existingArchitect) {
          console.log('Architect duplicated. Use existing one.');
          this.place.architects[architectID] = true;
        } else {
          console.log('Creating new architect.');
          const newArchitect = {
            count: 0,
            name: {
              zh: result
            },
            thumbnail: null
          };
          this.architectsCollection.doc(architectID).set(newArchitect);
          this.place.architects[architectID] = true;
        }
      }
    });
  }

  customCountry() {
    const modalRef = this.modalService.open(TextInputModalComponent, { centered: true });
    modalRef.componentInstance.title = "新增國家";
    modalRef.result.then((result) => {
      if (result !== undefined) {
        const countryID = result.split('/').join('-');
        const existingCountry = this.getCountry(countryID);
        if (existingCountry) {
          console.log('Country duplicated. Use existing one.');
          this.place.country = countryID;
        } else {
          console.log('Creating new country.');
          const newCountry = {
            count: 0,
            name: {
              zh: result
            },
            thumbnail: null,
            emoji: "🚩"
          };
          this.firestore.collection('countries').doc(countryID).set(newCountry);
          this.place.country = countryID;
        }
      }
    });
  }

  tagToggled(event: any, id: string): void {
    if (event.target.checked) {
      this.place.tags[id] = true;
    } else {
      delete this.place.tags[id];
    }
  }

  prizeToggled(event: any, id: string): void {
    var prize_goals = this.place.prize_goals || {};
    if (event.target.checked) {
      prize_goals[id] = true;
    } else {
      delete prize_goals[id];
    }
    this.place.prize_goals = prize_goals;
  }


  hideSuccess(): void {
    this.showSuccess = false;
  }

  save(): void {
    if (!this.place.name.zh) {
      window.alert("名稱為必填");
      return
    }
    if (!this.place.subtitle.zh) {
      window.alert("描述為必填");
      return
    }
    if (!this.place.address.zh) {
      window.alert("地址為必填");
      return
    }
    if (!this.place.navigation_url) {
      window.alert("地圖網址為必填");
      return
    }
    if (Object.keys(this.place.architects).length == 0) {
      window.alert("至少需要選擇一位建築師(記得按「加入」)");
      return
    }
    if (Object.keys(this.place.tags).length == 0) {
      window.alert("至少需要勾選一個分類");
      return
    }
    if (!this.place.country) {
      window.alert("國家為必選");
      return
    }
    if (this.place.links.length == 0) {
      window.alert("至少提供一個資訊連結");
      return
    }
    this.showSuccess = false;
    const newID = this.place.name.zh.split('/').join('-');
    this.placesCollection.doc(newID).set(this.place)
      .then(() => {
        window.alert("地點新增成功");
        this.router.navigateByUrl('backstage/places/' + newID);
      }).catch(error => {
        window.alert(error.message);
      });
  }

}
