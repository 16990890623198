import { Component, OnInit } from '@angular/core';
import { ArchitectsService } from '../architects.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-architects',
  templateUrl: './architects.component.html',
  styleUrls: ['./architects.component.css']
})
export class ArchitectsComponent implements OnInit {

  architects: any[] = [];
  page = 1;
  pageSize = 20;

  constructor(
    private architectsService: ArchitectsService,
    private router: Router,
  ) {
    this.architectsService.architects
      .subscribe(architects => this.architects = architects);
    this.page = this.architectsService.page;
  }

  ngOnInit(): void {
  }

  public onPageChange(pageNum: number): void {
    this.architectsService.page = pageNum;
  }

  getImgSource(architect: any): string {
    const defaultImg = 'assets/icon-profile-empty.svg';
    if (architect.thumbnail && !(architect.thumbnail.includes('https://thumbnail.com') || architect.thumbnail.includes('https://thumnail.com'))) {
      return architect.thumbnail;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-profile-empty.svg';
  }

  add(): void {
    this.router.navigateByUrl('backstage/architects/create');
  }

}
