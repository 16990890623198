import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PlacesService } from '../places.service';
import { ArchitectsService } from '../architects.service';
import { SubmissionsService } from '../submissions.service';

import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-backstage',
  templateUrl: './backstage.component.html',
  styleUrls: ['./backstage.component.css']
})
export class BackstageComponent implements OnInit {

  places: any[] = [];
  architects: any[] = [];
  pendingSubmissions: any[] = [];

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : [...this.places.filter(p => p.name.zh.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 8), ...this.architects.filter(a => a.name.zh.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 8)]
      ),
    )

  resultFormatter = (result: any) => {
    if (result.type == 'p') {
      return result.name.zh + ' ' + '(地點)'
    }
    if (result.type == 'a') {
      return result.name.zh + ' ' + '(建築師)'
    }
    return result.name.zh
  };
  selectedResult: any;

  constructor(
    public auth: AngularFireAuth,
    private router: Router,
    private placesService: PlacesService,
    private architectsService: ArchitectsService,
    private submissionsService: SubmissionsService,
  ) {
    this.placesService.places
      .subscribe((places) => {
        this.places = places.map((obj) => {
            obj['type'] = 'p';
            return obj;
        });
      });
    this.architectsService.architects
      .subscribe((architects) => {
        this.architects = architects.map((obj) => {
            obj['type'] = 'a';
            return obj;
        });
      });
    this.submissionsService.submissions
      .subscribe((submissions) => {
        this.pendingSubmissions = submissions.filter(s => s.status=='submitted');
      });
  }

  onSearchSelect(item: any){
    const result = item.item;
    if (result.type == 'p') {
      this.router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => {
          var url = 'backstage/places/' + encodeURIComponent(result.ID);
          // Angular has a bug that parenthesises in url are interpreted for different purposes,
          // Causing route error.
          // We encode them to fix the issue.
          // https://github.com/angular/angular/issues/10280
          url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
          this.router.navigateByUrl(url);
        });
    } else if (result.type == 'a') {
      this.router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => {
          var url = 'backstage/architects/' + encodeURIComponent(result.ID);
          // Angular has a bug that parenthesises in url are interpreted for different purposes,
          // Causing route error.
          // We encode them to fix the issue.
          // https://github.com/angular/angular/issues/10280
          url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
          this.router.navigateByUrl(url);
        });
    }
  }

  ngOnInit(): void {
  }

  logout() {
    this.auth.signOut().then(() => {
      // Sign-out successful.
      console.log('Sign-out successful');
      this.router.navigate(['login']);
    }).catch((error) => {
      // An error happened.
      window.alert(error.message)
    });
  }
}
