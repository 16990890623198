import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TempArchGoalsService {

  tempArchGoals: Observable<any[]>;
  page: number = 1;

  constructor(firestore: AngularFirestore) {
    this.tempArchGoals = firestore.collection('temp-arch-goals', ref => ref.orderBy('start', 'desc')).valueChanges({ idField: 'ID' });
  }
}
