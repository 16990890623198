import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-photo-modal',
  templateUrl: './photo-modal.component.html',
  styleUrls: ['./photo-modal.component.css']
})
export class PhotoModalComponent implements OnInit {

  @Input() media: any;
  @Input() mediaIndex: any;
  @Input() showRemove: boolean = true;
  @Input() showPick: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  delete(): void {
    this.activeModal.close(this.mediaIndex);
  }

  pick(): void {
    this.activeModal.close(this.mediaIndex);
  }

}
