import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Location } from '@angular/common';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ExcelExportService } from '../excel-export.service';
import { Query } from '@firebase/firestore-types';
import { switchMap, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-prize-goal-detail',
  templateUrl: './prize-goal-detail.component.html',
  styleUrls: ['./prize-goal-detail.component.css']
})
export class PrizeGoalDetailComponent implements OnInit {

  participants$: Observable<any[]>;
  participantIDs: string[] = [];

  private doc: AngularFirestoreDocument<any>;
  goal?: any;
  showSuccess: boolean = false;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  redeemHoveredDate: NgbDate | null = null;

  redeemFromDate: NgbDate | null = null;
  redeemToDate: NgbDate | null = null;

  redeemFromTime = {
    hour: 0,
    minute: 0,
  };
  redeemToTime = {
    hour: 0,
    minute: 0,
  };

  broadcast: string | null = null;

  private broadcastCollection: AngularFirestoreCollection<any>;
  sendingBroadcast: boolean = false;

  generatingParticipantList: boolean = false;

  participatedProfiles?: any[];

  journeyUID = '';
  generatingJourney = false;

  constructor(
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private location: Location,
    calendar: NgbCalendar,
    private excelService: ExcelExportService,
  ) {
    const id = this.route.snapshot.paramMap.get('id');
    this.broadcastCollection = firestore.collection('push-announcements');
    this.participants$ = firestore.collection('places', ref => ref.where('prize_goals.'+id, '==', true)).valueChanges({ idField: 'ID' });
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.doc = firestore.doc<any>('prize-goals/'+id);
    this.doc.valueChanges()
      .subscribe(goal => {
        this.goal = goal;
        this.fromDate = new NgbDate(goal.start.toDate().getFullYear(), goal.start.toDate().getMonth() + 1, goal.start.toDate().getDate());
        this.toDate = new NgbDate(goal.end.toDate().getFullYear(), goal.end.toDate().getMonth() + 1, goal.end.toDate().getDate());
        this.redeemFromDate = new NgbDate(goal.redeem_start.toDate().getFullYear(), goal.redeem_start.toDate().getMonth() + 1, goal.redeem_start.toDate().getDate());
        this.redeemToDate = new NgbDate(goal.redeem_end.toDate().getFullYear(), goal.redeem_end.toDate().getMonth() + 1, goal.redeem_end.toDate().getDate());
        this.redeemFromTime = {
          hour: goal.redeem_start.toDate().getHours(),
          minute: goal.redeem_start.toDate().getMinutes(),
        };
        this.redeemToTime = {
          hour: goal.redeem_end.toDate().getHours(),
          minute: goal.redeem_end.toDate().getMinutes(),
        };
      });
    firestore.collection('profiles', ref => ref.where(`achievements.${id}`, '!=', null)).valueChanges({ idField: 'ID' })
      .subscribe(profiles => {
        this.participatedProfiles = profiles;
      });

    this.participants$.subscribe(participatedPlaces => {
      this.participantIDs = participatedPlaces.map(p => p.ID);
    });
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {

    this.showSuccess = false;

    if (!this.goal.name.zh) {
      window.alert("活動名稱為必填");
      return
    }

    if (!this.fromDate) {
      window.alert("請選擇開始日期");
      return;
    }

    if (!this.toDate) {
      window.alert("請選擇結束日期");
      return;
    }

    if (!this.redeemFromDate) {
      window.alert("請選擇開始打卡與兌換的日期");
      return;
    }

    if (!this.redeemToDate) {
      window.alert("請選擇結束打卡與兌換的日期");
      return;
    }

    if (!this.goal.info_link) {
      window.alert("請輸入活動規則連結");
      return;
    }

    this.goal.start = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    this.goal.end = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);

    this.goal.redeem_start = new Date(this.redeemFromDate.year, this.redeemFromDate.month - 1, this.redeemFromDate.day, this.redeemFromTime.hour, this.redeemFromTime.minute);
    this.goal.redeem_end = new Date(this.redeemToDate.year, this.redeemToDate.month - 1, this.redeemToDate.day, this.redeemToTime.hour, this.redeemToTime.minute);

    var reg=/^#[0-9A-F]{6}$/i;
    if (!reg.test(this.goal.color)) {
      window.alert("請輸入正確的色碼");
      return;
    }

    if (!this.goal.qualification) {
      window.alert("兌換資格須為大於0的數字");
      return;
    }

    if (this.goal) {
      this.doc.update(this.goal)
        .then(() => {
          this.showSuccess = true;
        }).catch(error => {
          window.alert(error.message);
        });
    }
  }

  hideSuccess(): void {
    this.showSuccess = false;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.redeemFromDate = null;
    this.redeemToDate = null;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  getDateText(date: NgbDate | null) {
    return date == null ?  '選擇日期' : date.year + '/' + date.month + '/' + date.day;
  }

  onRedeemDateSelection(date: NgbDate) {
    if (!this.redeemFromDate && !this.redeemToDate) {
      this.redeemFromDate = date;
    } else if (this.redeemFromDate && !this.redeemToDate && date.after(this.redeemFromDate)) {
      this.redeemToDate = date;
    } else {
      this.redeemToDate = null;
      this.redeemFromDate = date;
    }
  }

  isRedeemHovered(date: NgbDate) {
    return this.redeemFromDate && !this.redeemToDate && this.redeemHoveredDate && date.after(this.redeemFromDate) && date.before(this.redeemHoveredDate);
  }

  isRedeemInside(date: NgbDate) {
    return this.redeemToDate && date.after(this.redeemFromDate) && date.before(this.redeemToDate);
  }

  isRedeemRange(date: NgbDate) {
    return date.equals(this.redeemFromDate) || (this.redeemToDate && date.equals(this.redeemToDate)) || this.isRedeemInside(date) || this.isRedeemHovered(date);
  }

  isRedeemDisabled = (date: NgbDate) => {
    if (this.fromDate && date.before(this.fromDate)) {
      return true;
    } else if (this.toDate && date.after(this.toDate)) {
      return true;
    } else {
      return false;
    }
  }

  getImgSource(place: any): string {
    const defaultImg = 'assets/icon-pic-empty.svg';
    if (place.media && place.media.length > 0) {
      return place.media[0].url;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-pic-empty.svg';
  }

  nearbyAlertToggled(event: any): void {
    this.goal.nearby_alert = event.target.checked;
  }

  sendBroadcast() {
    if (this.broadcast) {
      this.sendingBroadcast = true;
      const id = this.route.snapshot.paramMap.get('id');
      this.broadcastCollection.add({
        announcement_type: "prize-goals",
        prize_goal: id,
        created: new Date(),
        content: this.broadcast,
      }).then(() => {
          window.alert("成功建立推播通知！系統將會陸續將通知發送至使用者裝置。");
          this.broadcast = null;
          this.sendingBroadcast = false;
        }).catch(error => {
          window.alert(error.message);
          this.broadcast = null;
          this.sendingBroadcast = false;
        });
    }
  }

  generateParticipantList() {
    if (this.participatedProfiles) {
      this.generatingParticipantList = true;
      const prize_goal = this.route.snapshot.paramMap.get('id');
      var exportJSON: any[] = [];
      this.participatedProfiles.forEach((profile) => {
        var gender = "未提供";
        if (profile.gender == "m") {
          gender = "男";
        } else if (profile.gender == "f") {
          gender = "女";
        } else if (profile.gender == "n") {
          gender = "其他";
        }
        const collected = profile.achievements[prize_goal || "null"];
        const redeemed_goals = profile.redeemed_goals;
        const row = {
          使用者ID: profile.ID,
          暱稱: profile.name,
          性別: gender,
          生日: profile.birthdate ? profile.birthdate.toDate().toLocaleDateString("en-US") : '使用者未提供',
          年齡: profile.birthdate ? this.getAge(profile.birthdate.toDate()) : 0,
          加入美感路徑日期: profile.created.toDate().toLocaleDateString("en-US"),
          已收集的地點數量: Object.keys(collected).length,
          兌獎時收集的地點數量: redeemed_goals ? redeemed_goals[prize_goal || "null"] || 0 : 0
        };
        exportJSON.push(row);
      });
      this.excelService.exportAsExcelFile(exportJSON, this.goal.name.zh + '參與者清單');
      this.generatingParticipantList = false;
    }
  }

  getAge(birthDate: Date) {
    var today = new Date();
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  downloadJourney() {
    if (!this.participatedProfiles || this.participatedProfiles.filter(p => p.ID === this.journeyUID).length === 0) {
      window.alert("輸入的使用者ID未參與此次活動，請確定該使用者ID位於參與者名單中。");
      return;
    }
    this.generatingJourney = true;
    const profile = this.participatedProfiles.filter(p => p.ID === this.journeyUID)[0];
    const prize_goal = this.route.snapshot.paramMap.get('id');
    this.firestore.collection('place-visits', ref => {
      let query: Query = ref;
      query = query.where('visitor_uid', '==', profile.ID);
      query = query.orderBy('date', 'desc');
      return query;
    }).valueChanges({ idField: 'id' })
      .pipe(
        take(1),
      )
      .subscribe(result => {
        const visits: any[] = result;
        const relatedVisits = visits.filter(v => {
          return this.participantIDs.includes(v.place_id);
        });

        var exportJSON: any[] = [];
        relatedVisits.forEach((v) => {
          const row = {
            使用者ID: profile.ID,
            暱稱: profile.name,
            打卡地點名稱: v.place_name.zh,
            打卡時間: v.date.toDate().toLocaleString(),
          };
          exportJSON.push(row);
        });
        this.excelService.exportAsExcelFile(exportJSON, `${profile.name}於${this.goal.name.zh}的路徑`);
        this.journeyUID = '';
        this.generatingJourney = false;
      });

  }

}
