import { Component, OnInit } from '@angular/core';
import { CountriesService } from '../countries.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})
export class CountriesComponent implements OnInit {

  countries: any[] = [];
  page = 1;
  pageSize = 20;

  constructor(
    private countriesService: CountriesService,
    private router: Router,
  ) {
    this.countriesService.countries
      .subscribe(countries => this.countries = countries);
    this.page = this.countriesService.page;

  }

  ngOnInit(): void {
  }

  public onPageChange(pageNum: number): void {
    this.countriesService.page = pageNum;
  }

  getImgSource(country: any): string {
    const defaultImg = 'assets/icon-pic-empty.svg';
    if (country.thumbnail && !(country.thumbnail.includes('https://thumbnail.com') || country.thumbnail.includes('https://thumnail.com'))) {
      return country.thumbnail;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-pic-empty.svg';
  }

  add(): void {
    this.router.navigateByUrl('backstage/countries/create');
  }

}
