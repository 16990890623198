<header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" routerLink="dashboard">美感路徑管理</a>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <input class="form-control form-control-dark w-100" type="text" placeholder="搜尋地點或建築師" aria-label="搜尋地點或建築師"
    [(ngModel)]="selectedResult" [ngbTypeahead]="search" [resultFormatter]="resultFormatter" [inputFormatter]="resultFormatter"
    (selectItem)="onSearchSelect($event)">
  <div class="navbar-nav">
    <div class="nav-item text-nowrap">
      <a class="nav-link px-3" role="button" (click)="logout()">登出</a>
    </div>
  </div>
</header>

<div class="container-fluid">
  <div class="row">
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div class="position-sticky pt-3">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" routerLink="dashboard" routerLinkActive="active">
              <i-feather name="home" class="feather"></i-feather>
              首頁
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="places" routerLinkActive="active">
              <i-feather name="map-pin" class="feather"></i-feather>
              地點
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="architects" routerLinkActive="active">
              <i-feather name="users" class="feather"></i-feather>
              建築師
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="countries" routerLinkActive="active">
              <i-feather name="globe" class="feather"></i-feather>
              國家
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="submissions" routerLinkActive="active">
              <i-feather name="inbox" class="feather"></i-feather>
              使用者建議地點
              <span class="badge bg-secondary">{{ pendingSubmissions.length }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="temp-arch-goals" routerLinkActive="active">
              <i-feather name="Clock" class="feather"></i-feather>
              短期活動展演
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="prize-goals" routerLinkActive="active">
              <i-feather name="Gift" class="feather"></i-feather>
              集章兌獎活動
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="launch-ad" routerLinkActive="active">
              <i-feather name="Paperclip" class="feather"></i-feather>
              App 啟動廣告
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="tags" routerLinkActive="active">
              <i-feather name="Tag" class="feather"></i-feather>
              地點標籤
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="create-visit" routerLinkActive="active">
              <i-feather name="Check" class="feather"></i-feather>
              手動打卡
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="account-requests" routerLinkActive="active">
              <i-feather name="Frown" class="feather"></i-feather>
              帳號移除
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
