<main>

  <div class="py-5 text-center">
    <h2>集章兌獎活動</h2>
  </div>

  <div class="pb-4 d-flex">
    <button class="btn btn-outline-primary flex-fill" type="button" (click)="add()">新增集章兌獎活動</button>
  </div>

  <div class="row g-5">

    <div class="col-12">
      <div class="list-group">
        <a class="list-group-item list-group-item-action"
          *ngFor="let goal of goals | slice: (page-1) * pageSize : page * pageSize"
          routerLink="/backstage/prize-goals/{{goal.ID}}">
          <div class="d-flex">
            <div class="flex-shrink-0">
              <i-feather name="Gift" class="feather"></i-feather>
            </div>
            <div class="flex-grow-1 ms-3">
              <h5 class="mt-0">{{goal.name.zh}}</h5>
              <p>{{goal.start.toMillis() | date:'yyyy/MM/dd'}} 至 {{goal.end.toMillis() | date:'yyyy/MM/dd'}}</p>
              <small
              [ngClass]="{
                'ongoing' : getStatusText(goal) == '進行中',
                'past' : getStatusText(goal) == '已結束',
                'upcoming' : getStatusText(goal) == '即將開始'
              }">
                {{getStatusText(goal)}} | {{goal.count}} 個地點
              </small>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="col-12" *ngIf="goals.length > 0">
      <ngb-pagination class="d-flex justify-content-center" [collectionSize]="goals.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="onPageChange(page)"></ngb-pagination>
    </div>

    <div class="col-12" *ngIf="goals.length == 0">
      <div class="py-5 text-center">
        <p>正在取得活動...</p>
      </div>
    </div>

  </div>

</main>

<footer class="my-5 pt-5 text-muted text-center text-small">
  <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
</footer>
