import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { TempArchGoalsService } from '../temp-arch-goals.service';
import { Observable } from 'rxjs';
import * as fs from 'firebase/firestore';

@Component({
  selector: 'app-architect-detail',
  templateUrl: './architect-detail.component.html',
  styleUrls: ['./architect-detail.component.css']
})
export class ArchitectDetailComponent implements OnInit {

  tempArchGoals: any[] = [];

  private doc: AngularFirestoreDocument<any>;
  architect?: any;
  showSuccess: boolean = false;
  uploadPercent?: number;
  showThumbnailUploadSuccess: boolean = false;

  places$: Observable<any[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    firestore: AngularFirestore,
    private location: Location,
    private storage: AngularFireStorage,
    private tempArchGoalsService: TempArchGoalsService,
  ) {
    const id = this.route.snapshot.paramMap.get('id') ?? "";
    this.doc = firestore.doc<any>('architects/'+id);
    this.doc.valueChanges()
      .subscribe(architect => {
        const temp_goals = architect.temp_goals ?? {};
        architect.temp_goals = temp_goals;
        this.architect = architect;
      });
    this.tempArchGoalsService.tempArchGoals
      .subscribe(goals => this.tempArchGoals = goals);
    const fieldPath = new fs.FieldPath('architects', id);
    this.places$ = firestore.collection('places', ref => ref.where(fieldPath, '==', true)).valueChanges({ idField: 'ID' });
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.showSuccess = false;
    if (this.architect) {
      this.doc.update(this.architect)
        .then(() => {
          this.showSuccess = true;
        }).catch(error => {
          window.alert(error.message);
        });
    }
  }

  hideSuccess(): void {
    this.showSuccess = false;
  }

  thumbnailUpload(event: any): void {

    this.showThumbnailUploadSuccess = false;

    const file = event.target.files[0];
    const filePath = 'architects/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task.percentageChanges().subscribe(progress => {
      if (progress) {
        this.uploadPercent = progress / 100.0;
      } else {
        this.uploadPercent = undefined;
      }
    });
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.architect.thumbnail = url;
            this.uploadPercent = undefined;
            this.showThumbnailUploadSuccess = true;
          });
        })
     )
    .subscribe();
  }

  hideThumbnailSuccess(): void {
    this.showThumbnailUploadSuccess = false;
  }

  getImgSource(architect: any): string {
    const defaultImg = 'assets/icon-profile-empty.svg';
    if (architect.thumbnail && !(architect.thumbnail.includes('https://thumbnail.com') || architect.thumbnail.includes('https://thumnail.com'))) {
      return architect.thumbnail;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-profile-empty.svg';
  }

  getPlaceImgSource(place: any): string {
    const defaultImg = 'assets/icon-pic-empty.svg';
    if (place.media && place.media[0] && !(place.media[0].url.includes('https://thumbnail.com') || place.media[0].url.includes('https://thumnail.com'))) {
      return place.media[0].url;
    }
    else {
      return defaultImg;
    }
  }

  onPlaceImgError(event: any) {
    event.target.src = 'assets/icon-pic-empty.svg';
  }

  delete(): void {
    if (this.architect) {
      if(confirm("確定要刪除建築師 " + this.architect.name.zh + ' 嗎？')) {
        this.doc.delete()
          .then(() => {
            window.alert('刪除成功');
            this.router.navigateByUrl('backstage/architects');
          }).catch(error => {
            window.alert(error.message);
          });
      }
    }
  }

  tagToggled(event: any, id: string): void {
    var temp_goals = this.architect.temp_goals || {};
    if (event.target.checked) {
      temp_goals[id] = true;
    } else {
      delete temp_goals[id];
    }
    this.architect.temp_goals = temp_goals;
  }


}
