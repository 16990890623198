<main>

  <div class="py-5 text-center">
    <h2>國家</h2>
  </div>

  <div class="pb-4 d-flex">
    <button class="btn btn-outline-primary flex-fill" type="button" (click)="add()">新增國家</button>
  </div>

  <div class="row g-5">

    <div class="col-12">
      <div class="list-group">
        <a class="list-group-item list-group-item-action"
          *ngFor="let country of countries | slice: (page-1) * pageSize : page * pageSize"
          routerLink="/backstage/countries/{{country.ID}}">
          <div class="d-flex">
            <div class="flex-shrink-0">
              <img [src]="getImgSource(country)" alt="..." class="img-fluid rounded"
                (error) ="onImgError($event)">
            </div>
            <div class="flex-grow-1 ms-3">
              <h5 class="mt-0">{{country.name.zh}}</h5>
              <p>{{country.emoji}}</p>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="col-12" *ngIf="countries.length > 0">
      <ngb-pagination class="d-flex justify-content-center" [collectionSize]="countries.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="onPageChange(page)"></ngb-pagination>
    </div>

    <div class="col-12" *ngIf="countries.length == 0">
      <div class="py-5 text-center">
        <p>正在取得國家...</p>
      </div>
    </div>

  </div>

</main>

<footer class="my-5 pt-5 text-muted text-center text-small">
  <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
</footer>
