import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-country-detail',
  templateUrl: './country-detail.component.html',
  styleUrls: ['./country-detail.component.css']
})
export class CountryDetailComponent implements OnInit {

  private doc: AngularFirestoreDocument<any>;
  country?: any;
  showSuccess: boolean = false;
  uploadPercent?: number;
  showThumbnailUploadSuccess: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    firestore: AngularFirestore,
    private location: Location,
    private storage: AngularFireStorage
  ) {
    const id = this.route.snapshot.paramMap.get('id');
    this.doc = firestore.doc<any>('countries/'+id);
    this.doc.valueChanges()
      .subscribe(country => this.country = country);

  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.showSuccess = false;
    if (this.country) {
      this.doc.update(this.country)
        .then(() => {
          this.showSuccess = true;
        }).catch(error => {
          window.alert(error.message);
        });
    }
  }

  hideSuccess(): void {
    this.showSuccess = false;
  }

  thumbnailUpload(event: any): void {

    this.showThumbnailUploadSuccess = false;

    const file = event.target.files[0];
    const filePath = 'countries/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task.percentageChanges().subscribe(progress => {
      if (progress) {
        this.uploadPercent = progress / 100.0;
      } else {
        this.uploadPercent = undefined;
      }
    });
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.country.thumbnail = url;
            this.uploadPercent = undefined;
            this.showThumbnailUploadSuccess = true;
          });
        })
     )
    .subscribe();
  }

  hideThumbnailSuccess(): void {
    this.showThumbnailUploadSuccess = false;
  }

  getImgSource(country: any): string {
    const defaultImg = 'assets/icon-pic-empty.svg';
    if (country.thumbnail && !(country.thumbnail.includes('https://thumbnail.com') || country.thumbnail.includes('https://thumnail.com'))) {
      return country.thumbnail;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-pic-empty.svg';
  }

  delete(): void {
    if (this.country) {
      if(confirm("確定要刪除建築師 " + this.country.name.zh + ' 嗎？')) {
        this.doc.delete()
          .then(() => {
            window.alert('刪除成功');
            this.router.navigateByUrl('backstage/countries');
          }).catch(error => {
            window.alert(error.message);
          });
      }
    }
  }

}
