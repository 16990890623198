<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
  <input type="text" class="form-control"
    [(ngModel)]="textInput">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">關閉</button>
  <button type="button" class="btn btn-primary" (click)="commit()">新增</button>
</div>
