import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Location } from '@angular/common';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-prize-goals',
  templateUrl: './create-prize-goals.component.html',
  styleUrls: ['./create-prize-goals.component.css']
})
export class CreatePrizeGoalsComponent implements OnInit {

  goal: any = {
    name: {
    },
    count: 0,
    color: '#FF9775',
    start: null,
    end: null,
    redeem_start: null,
    redeem_end: null,
    qualification: 10,
    nearby_alert: true,
    info_link: null
  };

  private goalCollection: AngularFirestoreCollection<any>;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  redeemHoveredDate: NgbDate | null = null;

  redeemFromDate: NgbDate | null = null;
  redeemToDate: NgbDate | null = null;

  constructor(
    private router: Router,
    firestore: AngularFirestore,
    private location: Location,
    calendar: NgbCalendar
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.goalCollection = firestore.collection('prize-goals');
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    if (!this.goal.name.zh) {
      window.alert("活動名稱為必填");
      return
    }

    if (!this.fromDate) {
      window.alert("請選擇開始日期");
      return;
    }

    if (!this.toDate) {
      window.alert("請選擇結束日期");
      return;
    }

    if (!this.redeemFromDate) {
      window.alert("請選擇開始兌換日期");
      return;
    }

    if (!this.redeemToDate) {
      window.alert("請選擇結束兌換日期");
      return;
    }

    if (!this.goal.qualification) {
      window.alert("兌換資格須為大於0的數字");
      return;
    }

    if (!this.goal.info_link) {
      window.alert("請輸入活動規則連結");
      return;
    }

    this.goal.start = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    this.goal.end = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);

    this.goal.redeem_start = new Date(this.redeemFromDate.year, this.redeemFromDate.month - 1, this.redeemFromDate.day);
    this.goal.redeem_end = new Date(this.redeemToDate.year, this.redeemToDate.month - 1, this.redeemToDate.day);

    const newID = this.goal.name.zh.split('/').join('-');
    this.goalCollection.doc(newID).set(this.goal)
      .then(() => {
        window.alert("活動新增成功");
        this.router.navigateByUrl('backstage/prize-goals/' + newID);
      }).catch(error => {
        window.alert(error.message);
      });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.redeemFromDate = null;
    this.redeemToDate = null;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  getDateText(date: NgbDate | null) {
    return date == null ?  '選擇日期' : date.year + '/' + date.month + '/' + date.day;
  }

  nearbyAlertToggled(event: any): void {
    this.goal.nearby_alert = event.target.checked;
  }

  onRedeemDateSelection(date: NgbDate) {
    if (!this.redeemFromDate && !this.redeemToDate) {
      this.redeemFromDate = date;
    } else if (this.redeemFromDate && !this.redeemToDate && date.after(this.redeemFromDate)) {
      this.redeemToDate = date;
    } else {
      this.redeemToDate = null;
      this.redeemFromDate = date;
    }
  }

  isRedeemHovered(date: NgbDate) {
    return this.redeemFromDate && !this.redeemToDate && this.redeemHoveredDate && date.after(this.redeemFromDate) && date.before(this.redeemHoveredDate);
  }

  isRedeemInside(date: NgbDate) {
    return this.redeemToDate && date.after(this.redeemFromDate) && date.before(this.redeemToDate);
  }

  isRedeemRange(date: NgbDate) {
    return date.equals(this.redeemFromDate) || (this.redeemToDate && date.equals(this.redeemToDate)) || this.isRedeemInside(date) || this.isRedeemHovered(date);
  }

  isRedeemDisabled = (date: NgbDate) => {
    if (this.fromDate && date.before(this.fromDate)) {
      return true;
    } else if (this.toDate && date.after(this.toDate)) {
      return true;
    } else {
      return false;
    }
  }

}
