import { Component, OnInit } from '@angular/core';
import { AccountRequestsService } from '../account-requests.service'

@Component({
  selector: 'app-account-requests',
  templateUrl: './account-requests.component.html',
  styleUrls: ['./account-requests.component.css']
})
export class AccountRequestsComponent implements OnInit {

  requests: any[] = [];
  page = 1;
  pageSize = 20;

  constructor(
    private accountRequestsService: AccountRequestsService,
  ) {
    this.accountRequestsService.requests
      .subscribe(requests => this.requests = requests);
    this.page = this.accountRequestsService.page;
  }

  ngOnInit(): void {
  }

  public onPageChange(pageNum: number): void {
    this.accountRequestsService.page = pageNum;
  }

}
