import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Location } from '@angular/common';
import * as fs from 'firebase/firestore';
import { placeTags } from '../place-tags';

@Component({
  selector: 'app-places-by-tag',
  templateUrl: './places-by-tag.component.html',
  styleUrls: ['./places-by-tag.component.css']
})
export class PlacesByTagComponent implements OnInit {

  places$: Observable<any[]>;
  tag: any;

  page = 1;
  pageSize = 20;

  constructor(
    private route: ActivatedRoute,
    firestore: AngularFirestore,
    private location: Location,
  ) {
    const id = this.route.snapshot.paramMap.get('id') ?? "";
    this.tag = placeTags.find(x => x.id == id);
    this.page = this.tag.page;
    const fieldPath = new fs.FieldPath('tags', id);
    this.places$ = firestore.collection('places', ref => ref.where(fieldPath, '==', true)).valueChanges({ idField: 'ID' });
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  architectsToString(architects: any): string {
    return Object.keys(architects).join(", ")
  }

  public onPageChange(pageNum: number): void {
    this.tag.page = pageNum;
  }

  getImgSource(place: any): string {
    const defaultImg = 'assets/icon-pic-empty.svg';
    if (place.media && place.media.length > 0) {
      return place.media[0].url;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-pic-empty.svg';
  }

}
