<main>

  <div class="py-5 text-center">
    <img class="img-icon" src="../assets/icon-app-black.svg" alt="">
    <h1 class="h3 mt-3 fw-normal">Aesthetics Map 管理後台</h1>
  </div>

  <div class="row g-5">

    <div class="col-12">
      <div class="list-group">
        <a class="list-group-item list-group-item-action"
          *ngFor="let submission of submissions | slice: (page-1) * pageSize : page * pageSize"
          routerLink="/backstage/places/{{submission.place_id}}">
          <div class="d-flex">
            <div class="flex-shrink-0">
              <img [src]="getImgSource(submission.media[0].provider)" alt="..."
                class="img-fluid rounded-circle"
                (error) ="onImgError($event)">
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1">
                  {{getProviderName(submission.media[0].provider)}} 對 {{submission.place_name.zh}} 新增了 {{submission.media.length}} 張照片
                </h6>
                <small>
                  {{submission.created.toMillis() | date:'yyyy/MM/dd h:mm:ss a'}}
                </small>
              </div>
              <div class="d-flex flex-row flex-nowrap overflow-auto">
                <div *ngFor="let media of submission.media; let i = index" class="card">
                  <img src="{{ media.url }}" alt="..." class="rounded card-img overflow-hidden">
                  <div class="card-img-overlay">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="col-12" *ngIf="submissions.length > 0">
      <ngb-pagination class="d-flex justify-content-center" [collectionSize]="submissions.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="onPageChange(page)"></ngb-pagination>
    </div>

    <div class="col-12" *ngIf="submissions.length == 0">
      <div class="py-5 text-center">
        <p>正在取得資料...</p>
      </div>
    </div>

  </div>

</main>
<footer class="my-5 pt-5 text-muted text-center text-small">
  <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
</footer>
