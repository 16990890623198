<main>

  <div class="py-5 text-center">
    <h2>地點標籤</h2>
  </div>

  <div class="row g-5">

    <div class="col-12">
      <div class="list-group">
        <a class="list-group-item list-group-item-action"
          *ngFor="let placeTag of placeTags"
          routerLink="/backstage/tags/{{placeTag.id}}">
          <div class="d-flex flex-row">
            <i-feather name="Tag" class="feather"></i-feather>
            <div class="ms-3">
              {{placeTag.name}}
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="col-12" *ngIf="placeTags.length == 0">
      <div class="py-5 text-center">
        <p>正在取得標籤...</p>
      </div>
    </div>

  </div>

</main>

<footer class="my-5 pt-5 text-muted text-center text-small">
  <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
</footer>
