export var placeTags: any[] = [
  { id: 'architect', name: '普立茲克建築獎', page: 1 },
  { id: 'tid', name: '台灣室內設計大賽TID', page: 1 },
  { id: 'ada', name: 'ada 新銳建築獎', page: 1 },
  { id: 'gd', name: '日本GoodDesign', page: 1 },
  { id: 'if-award', name: '德國iF設計獎', page: 1 },
  { id: 'building', name: '大樓', page: 1 },
  { id: 'apartment', name: '公寓', page: 1 },
  { id: 'historical-building', name: '歷史建築', page: 1 },
  { id: 'religional-building', name: '宗教建築', page: 1 },
  { id: 'self-constructed', name: '自地自建', page: 1 },
  { id: 'public-building', name: '公共建築', page: 1 },
  { id: 'commercial-building', name: '商用建築', page: 1 },
  { id: 'hotel', name: '飯店/旅宿空間', page: 1 },
  { id: 'dining-area', name: '餐飲空間', page: 1 },
  { id: 'shopping-area', name: '購物空間', page: 1 },
  { id: 'recreational-area', name: '休閒空間', page: 1 },
  { id: 'exhibition-space', name: '展覽空間', page: 1 },
  { id: 'temp-construct', name: '臨時建築與裝置', page: 1 },
  { id: 'public-area', name: '公共空間', page: 1 },
  { id: 'working-space', name: '工作空間', page: 1 },
  { id: 'scenery-env', name: '景觀環境', page: 1 },
  { id: 'art', name: '藝術', page: 1 },
  { id: 'life-and-style', name: '生活人文', page: 1 },
  { id: 'exhibition', name: '設計展覽', page: 1 },
  { id: 'aesthetics-list', name: '美感路徑推薦名單', page: 1 },
  { id: 'tokyo-list', name: '東京建築女子推薦', page: 1 },
  { id: 'cafe', name: '咖啡廳', page: 1 },
  { id: 'bar', name: '酒吧', page: 1 },
  { id: 'restaurant', name: '餐廳', page: 1 },
  { id: 'golden-pin-design', name: '金點設計獎', page: 1 }
];
