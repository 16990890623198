import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';

import { LoginComponent } from './login/login.component';
import { BackstageComponent } from './backstage/backstage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlacesComponent } from './places/places.component';
import { PlaceDetailComponent } from './place-detail/place-detail.component';
import { ArchitectsComponent } from './architects/architects.component';
import { ArchitectDetailComponent } from './architect-detail/architect-detail.component';
import { CreatePlaceComponent } from './create-place/create-place.component';
import { CreateArchitectComponent } from './create-architect/create-architect.component';
import { CountriesComponent } from './countries/countries.component';
import { CountryDetailComponent } from './country-detail/country-detail.component';
import { CreateCountryComponent } from './create-country/create-country.component';
import { SubmissionsComponent } from './submissions/submissions.component';
import { SubmissionDetailComponent } from './submission-detail/submission-detail.component';
import { TempArchGoalsComponent } from './temp-arch-goals/temp-arch-goals.component';
import { TempArchGoalsDetailComponent } from './temp-arch-goals-detail/temp-arch-goals-detail.component';
import { CreateTempArchGoalComponent } from './create-temp-arch-goal/create-temp-arch-goal.component';
import { LaunchAdComponent } from './launch-ad/launch-ad.component';
import { TagsComponent } from './tags/tags.component';
import { PlacesByTagComponent } from './places-by-tag/places-by-tag.component';
import { AccountRequestsComponent } from './account-requests/account-requests.component';
import { PrizeGoalsComponent } from './prize-goals/prize-goals.component';
import { CreatePrizeGoalsComponent } from './create-prize-goals/create-prize-goals.component';
import { PrizeGoalDetailComponent } from './prize-goal-detail/prize-goal-detail.component';
import { CreateVisitComponent } from './create-visit/create-visit.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToBackstage = () => redirectLoggedInTo(['backstage']);

const routes: Routes = [
  { path: '', redirectTo: '/backstage/dashboard', pathMatch: 'full' },
  {
    path: 'backstage',
    component: BackstageComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      { path: '', redirectTo: '/backstage/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'places',
        component: PlacesComponent,
      },
      { path: 'places/create', component: CreatePlaceComponent, pathMatch: 'full' },
      { path: 'places/:id', component: PlaceDetailComponent },
      {
        path: 'architects',
        component: ArchitectsComponent,
      },
      { path: 'architects/create', component: CreateArchitectComponent, pathMatch: 'full' },
      { path: 'architects/:id', component: ArchitectDetailComponent },
      {
        path: 'countries',
        component: CountriesComponent,
      },
      { path: 'countries/create', component: CreateCountryComponent, pathMatch: 'full' },
      { path: 'countries/:id', component: CountryDetailComponent },
      {
        path: 'submissions',
        component: SubmissionsComponent,
      },
      { path: 'submissions/:id', component: SubmissionDetailComponent },
      {
        path: 'temp-arch-goals',
        component: TempArchGoalsComponent,
      },
      { path: 'temp-arch-goals/create', component: CreateTempArchGoalComponent, pathMatch: 'full' },
      { path: 'temp-arch-goals/:id', component: TempArchGoalsDetailComponent },
      {
        path: 'prize-goals',
        component: PrizeGoalsComponent,
      },
      { path: 'prize-goals/create', component: CreatePrizeGoalsComponent, pathMatch: 'full' },
      { path: 'prize-goals/:id', component: PrizeGoalDetailComponent },
      {
        path: 'launch-ad',
        component: LaunchAdComponent,
      },
      {
        path: 'tags',
        component: TagsComponent,
      },
      { path: 'tags/:id', component: PlacesByTagComponent },
      {
        path: 'create-visit',
        component: CreateVisitComponent,
      },
      {
        path: 'account-requests',
        component: AccountRequestsComponent,
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToBackstage }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
