<div class="container">
  <main>
    <div class="py-5 text-center">
      <h2>App 啟動廣告</h2>
    </div>

    <div class="row g-5">
      <div class="col" *ngIf="launchAd">

        <h4 class="mb-3">設定廣告</h4>

        <div class="row g-3">

          <div class="d-flex flex-row flex-nowrap overflow-auto" *ngIf="launchAd.media">
            <div class="card">
              <img src="{{ launchAd.media.url }}" alt="..." class="rounded card-img overflow-hidden">
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label for="thumbnailFile" class="form-label">更換圖片</label>
              <input class="form-control" type="file" id="thumbnailFile" accept=".jpg,.png"
                (change)="thumbnailUpload($event)">
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="uploadPercent">
              正在上傳 <strong>{{ uploadPercent | percent }}</strong>
            </div>
            <div class="alert alert-success alert-dismissible fade show" role="alert"
              *ngIf="showThumbnailUploadSuccess">
              <strong>上傳成功!</strong> 你可以持續更換其他照片，直到按下儲存鈕
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="hideThumbnailSuccess()"></button>
            </div>
          </div>

          <div class="col-12">
            <label for="link" class="form-label">點擊後開啟的連結</label>
            <input type="text" class="form-control" name="link" id="link" placeholder="https://aestheticsmap.com" required
              [(ngModel)]="launchAd.link">
          </div>

          <div class="col-6">
            <label for="frequency" class="form-label">顯示頻率 (每開啟 app 幾次要顯示廣告?)</label>
            <input type="number" class="form-control" name="frequency" id="frequency" placeholder="10" required
              [(ngModel)]="launchAd.frequency">
          </div>

        </div>

        <hr class="my-4">

        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <button class="btn btn-primary" type="submit" (click)="save()">儲存</button>
          <button class="btn btn-danger" type="button" (click)="delete()">移除廣告</button>
        </div>

      </div>
      <div class="col" *ngIf="!launchAd">
        <div class="d-grid gap-2 col-6 mx-auto" *ngIf="isLoaded">
          <button type="button" class="btn btn-primary" (click)="create()">建立 App 啟動廣告</button>
        </div>
        <p class="text-center" *ngIf="!isLoaded">正在讀取資料...</p>
      </div>
    </div>
  </main>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
  </footer>
</div>
