import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import { Home, MapPin, Users, Trash2, Globe, Inbox, Clock, Paperclip, Tag, Frown, Gift, Check } from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  Home,
  MapPin,
  Users,
  Trash2,
  Globe,
  Inbox,
  Clock,
  Paperclip,
  Tag,
  Frown,
  Gift,
  Check,
};

@NgModule({
  declarations: [],
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
