<body class="text-center">

  <main class="form-signin">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <img class="mb-4" src="../assets/icon-app-black.svg" alt="">
      <h1 class="h3 mb-3 fw-normal">美感路徑管理後台</h1>

      <div class="form-floating">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="email">
        <label for="floatingInput">電子郵件</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" formControlName="password">
        <label for="floatingPassword">密碼</label>
      </div>

      <button class="w-100 btn btn-lg btn-primary" type="submit" [disabled]="loggingIn">登入</button>
      <p class="mt-5 mb-3 text-muted">&copy; 木介空間設計 MUJIE DESIGN</p>
    </form>
  </main>

</body>
