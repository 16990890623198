<div class="container" *ngIf="place">
  <main>
    <div class="py-5 text-center">
      <img *ngIf="place.media[0]" src="{{ place.media[0].url }}" alt="..." class="img-fluid rounded-circle">
      <h2>{{ place.name.zh }}</h2>
    </div>

    <div class="row g-5">
      <div class="col">
        <h4 class="mb-3">基本資料</h4>
        <form class="needs-validation" novalidate>
          <div class="row g-3">

            <div class="col-12">
              <label for="name" class="form-label">地點名稱</label>
              <input type="text" class="form-control" name="name" id="name" placeholder="#OMG" required
                [(ngModel)]="place.name.zh">
              <div class="invalid-feedback">
                不可接受的地點名稱
              </div>
            </div>

            <div class="col-12">
              <label for="subtitle" class="form-label">描述</label>
              <input type="text" class="form-control" name="subtitle" id="subtitle" placeholder="建築師事務所設計" required
                [(ngModel)]="place.subtitle.zh">
              <div class="invalid-feedback">
                不可接受的描述
              </div>
            </div>

            <div class="col-md-3">
              <label for="country" class="form-label">國家</label>
              <select class="form-select" name="country" id="country" required
                [(ngModel)]="place.country">
                <option *ngFor="let country of countries" [ngValue]="country.ID">{{country.name.zh}}</option>
              </select>
              <div class="invalid-feedback">
                請選擇國家
              </div>
            </div>

            <div class="col-md-9">
              <label for="address" class="form-label">地址</label>
              <input type="text" class="form-control" name="address" id="address" placeholder="香港大角咀海泓道1號" required
                [(ngModel)]="place.address.zh">
              <div class="invalid-feedback">
                不可接受的地址
              </div>
            </div>

            <div class="col-12">
              <button type="button" class="btn btn-outline-primary" (click)="customCountry()">找不到國家?</button>
            </div>

            <div class="col-md-4">
              <label for="latitude" class="form-label">緯度</label>
              <input type="text" class="form-control" name="latitude" id="latitude" placeholder="23.225" required
                [ngModel]="place.geopoint.latitude" (ngModelChange)="latitudeChanged($event)">
              <div class="invalid-feedback">
                緯度為必填
              </div>
            </div>

            <div class="col-md-4">
              <label for="longitude" class="form-label">經度</label>
              <input type="text" class="form-control" name="longitude" id="longitude" placeholder="121.2511" required
                [ngModel]="place.geopoint.longitude" (ngModelChange)="longitudeChanged($event)">
              <div class="invalid-feedback">
                經度為必填
              </div>
            </div>

            <div class="col-md-4">
              <label for="geohash" class="form-label">Geohash(自動計算)</label>
              <input type="text" class="form-control" name="geohash" id="geohash" placeholder="wecnvyu0zs" required
                [(ngModel)]="place.geohash" readonly>
            </div>

            <div class="col-md-4">
              <label for="checkin-distance" class="form-label">可打卡範圍(公尺, 選填)</label>
              <input type="number" class="form-control" name="checkin-distance" id="checkin-distance" placeholder="100" required
                [(ngModel)]="place.checkin_distance">
            </div>

            <div class="col-12">
              <label for="navigation_url" class="form-label">地圖網址</label>
              <input type="text" class="form-control" name="navigation_url" id="navigation_url"
                placeholder="https://goo.gl/maps/CYNAaGxB8dJapdUi6"
                required
                [(ngModel)]="place.navigation_url">
              <div class="invalid-feedback">
                不可接受的地圖網址
              </div>
            </div>

          </div>

          <hr class="my-4">

          <h4 class="mb-3">照片</h4>

          <div class="row g-3">

            <div class="d-flex flex-row flex-nowrap overflow-auto">
              <div *ngFor="let media of place.media; let i = index" class="card">
                <a (click)="openPhotoModal(media, i)">
                  <img src="{{ media.url }}" alt="..." class="rounded card-img overflow-hidden">
                  <div class="card-img-overlay">
                    <span class="badge bg-dark" *ngIf="media.provider">{{media.provider.name}} 上傳</span>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="addPhoto" class="form-label">新增照片</label>
                <input class="form-control" type="file" id="addPhoto" accept=".jpg,.png"
                  (change)="photoUpload($event)">
              </div>
              <div class="alert alert-secondary" role="alert" *ngIf="uploadPercent">
                正在上傳 <strong>{{ uploadPercent | percent }}</strong>
              </div>
              <div class="alert alert-success alert-dismissible fade show" role="alert"
                *ngIf="showPhotoUploadSuccess">
                <strong>上傳成功!</strong> 你可以持續編輯照片，直到按下儲存鈕
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="hidePhotoSuccess()"></button>
              </div>
            </div>

          </div>

          <hr class="my-4">

          <h4 class="mb-3">建築師</h4>

          <div class="row g-3">

            <div class="input-group"
              *ngFor="let item of place.architects | keyvalue">
              <input type="text" class="form-control" value="{{item.key}}" readonly>
              <button type="button" class="btn btn-danger" (click)="removeArchitect(item.key)">移除</button>
            </div>

            <div class="input-group">
              <input class="form-control" type="text" placeholder="加入建築師" aria-label="加入建築師"
                [(ngModel)]="selectedArchitect" [ngbTypeahead]="searchArchitect" [resultFormatter]="architectResultFormatter" [inputFormatter]="architectResultFormatter"
                [ngModelOptions]="{standalone: true}">
              <button type="button" class="btn btn-primary" (click)="addArchitect()">加入</button>
            </div>

            <div class="col-12 text-end">
              <button type="button" class="btn btn-outline-primary" (click)="customArchitect()">找不到建築師?</button>
            </div>

          </div>

          <hr class="my-4">

          <h4 class="mb-3">分類</h4>

          <div class="row g-3">

            <div class="col-md-4" *ngFor="let tag of placeTags">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="tag-{{tag.id}}" id="tag-{{tag.id}}"
                  [checked]="place.tags[tag.id]"
                  (change)="tagToggled($event, tag.id)">
                <label class="form-check-label" for="tag-{{tag.id}}">{{tag.name}}</label>
              </div>
            </div>

          </div>

          <hr class="my-4">

          <h4 class="mb-3">加入集章兌獎活動</h4>

          <div class="row g-3">

            <div class="col-md-4" *ngFor="let prizeGoal of prizeGoals">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="prizeGoal-{{prizeGoal.ID}}" id="prizeGoal-{{prizeGoal.ID}}"
                  [checked]="place.prize_goals[prizeGoal.ID]"
                  (change)="prizeToggled($event, prizeGoal.ID)">
                <label class="form-check-label" for="prizeGoal-{{prizeGoal.ID}}">{{prizeGoal.name.zh}}</label>
              </div>
            </div>

            <div class="col" *ngIf="prizeGoals.length === 0">目前無任何活動</div>

          </div>

          <hr class="my-4">

          <h4 class="mb-3">連結</h4>

          <div class="row g-3">

            <div class="input-group"
              *ngFor="let link of place.links; let i = index">
              <input type="text" class="form-control" value="{{link}}" readonly>
              <button type="button" class="btn btn-danger" (click)="removeLink(i)">移除</button>
            </div>

            <div class="input-group">
              <input type="text" class="form-control" placeholder="連結網址"
                [(ngModel)]="linkInput" [ngModelOptions]="{standalone: true}">
              <button type="button" class="btn btn-primary" (click)="addLink()">新增</button>
            </div>

          </div>

          <hr class="my-4">

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button class="btn btn-primary" type="submit" (click)="save()">儲存</button>
            <button class="btn btn-outline-secondary" type="button" (click)="goBack()">回上一頁</button>
          </div>

          <div class="alert alert-success alert-dismissible fade show mt-3" role="alert"
            *ngIf="showSuccess">
            <strong>儲存成功!</strong> 你可以繼續編輯，或回上一頁
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="hideSuccess()"></button>
          </div>

          <hr class="my-4">

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button class="btn btn-danger" type="button" (click)="delete()">刪除這個地點</button>
          </div>

        </form>

      </div>

    </div>

  </main>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2023 PATH LIFE Ltd.</p>
  </footer>
</div>
