import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Location } from '@angular/common';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-temp-arch-goal',
  templateUrl: './create-temp-arch-goal.component.html',
  styleUrls: ['./create-temp-arch-goal.component.css']
})
export class CreateTempArchGoalComponent implements OnInit {

  goal: any = {
    name: {
    },
    count: 0,
    color: '#000000',
    start: null,
    end: null
  };

  private goalCollection: AngularFirestoreCollection<any>;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  constructor(
    private router: Router,
    firestore: AngularFirestore,
    private location: Location,
    calendar: NgbCalendar
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.goalCollection = firestore.collection('temp-arch-goals');
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    if (!this.goal.name.zh) {
      window.alert("活動名稱為必填");
      return
    }

    if (!this.fromDate) {
      window.alert("請選擇開始日期");
      return;
    }

    if (!this.toDate) {
      window.alert("請選擇結束日期");
      return;
    }

    this.goal.start = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    this.goal.end = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);

    const newID = this.goal.name.zh.split('/').join('-');
    this.goalCollection.doc(newID).set(this.goal)
      .then(() => {
        window.alert("活動新增成功");
        this.router.navigateByUrl('backstage/temp-arch-goals/' + newID);
      }).catch(error => {
        window.alert(error.message);
      });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  getDateText(date: NgbDate | null) {
    return date == null ?  '選擇日期' : date.year + '/' + date.month + '/' + date.day;
  }

}
