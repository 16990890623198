import { Component, OnInit } from '@angular/core';
import { PrizeGoalsService } from '../prize-goals.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-prize-goals',
  templateUrl: './prize-goals.component.html',
  styleUrls: ['./prize-goals.component.css']
})
export class PrizeGoalsComponent implements OnInit {

  goals: any[] = [];
  page = 1;
  pageSize = 20;

  constructor(
    private prizeGoalsService: PrizeGoalsService,
    private router: Router,
  ) {
    this.prizeGoalsService.prizeGoals
      .subscribe(goals => this.goals = goals);
    this.page = this.prizeGoalsService.page;
  }

  ngOnInit(): void {
  }

  public onPageChange(pageNum: number): void {
    this.prizeGoalsService.page = pageNum;
  }

  add(): void {
    this.router.navigateByUrl('backstage/prize-goals/create');
  }

  getStatusText(goal: any): string {
    var now = new Date();
    if (now > goal.end.toDate()) {
      return '已結束';
    } else if (now <= goal.end.toDate() && now > goal.start.toDate()) {
      return '進行中';
    } else {
      return '即將開始';
    }
  }



}
