import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  places: Observable<any[]>;
  page: number = 1;

  constructor(firestore: AngularFirestore) {
    this.places = firestore.collection('places').valueChanges({ idField: 'ID' });
  }

}
