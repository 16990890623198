import { Component, OnInit } from '@angular/core';
import { MediaSubmissionsService } from '../media-submissions.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  submissions: any[] = [];
  page = 1;
  pageSize = 20;

  constructor(
    private mediaSubmissionsService: MediaSubmissionsService
  ) {
    this.mediaSubmissionsService.submissions
      .subscribe(submissions => this.submissions = submissions);
    this.page = this.mediaSubmissionsService.page;
  }

  ngOnInit(): void {
  }

  public onPageChange(pageNum: number): void {
    this.mediaSubmissionsService.page = pageNum;
  }

  getImgSource(provider: any): string {
    const defaultImg = 'assets/icon-profile-empty.svg';
    if (provider && provider.avatar && !(provider.avatar.includes('https://thumbnail.com') || provider.avatar.includes('https://thumnail.com'))) {
      return provider.avatar;
    }
    else {
      return defaultImg;
    }
  }

  onImgError(event: any) {
    event.target.src = 'assets/icon-profile-empty.svg';
  }

  getProviderName(provider: any): string {
    if (provider && provider.name) {
      return provider.name;
    }
    else {
      return "使用者";
    }
  }

}
